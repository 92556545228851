/* eslint-disable no-underscore-dangle */
import { GoogleAnalyticsService } from "../components/google-analytics"

const USER_SETTINGS_EDELIVERY = {
  INSIGHT: {
    route: 'client.agent.e-delivery',
    event: 'Insight View Document Preferences',
  },
  CP: {
    route: 'user-settings.e-delivery',
    event: 'Client Portal View Document Preferences',
  },
}

const ALLOWED_PAPERLESS_DELIVERY_ROLES = [
  {
    role: 'Joint Owner',
    tc: '184',
  },
  {
    role: 'Successor Owner',
    tc: '78',
  },
  {
    role: 'Original Owner',
    tc: '1012300017',
  },
  {
    role: 'Owner',
    tc: '8',
  },
]

export class UserSettingViewStateService {
  static $inject: string[] = ['configService', 'authService', '$state', 'googleAnalyticsService']

  userSession: any
  client: any

  constructor(private configService: any, private authService: any, private $state: any, private googleAnalyticsService: GoogleAnalyticsService) {}

  setClient(client: any): void {
    this.client = client
  }

  viewDeliveryPreferences(): void {
    const userSettings = this.isInsight ? USER_SETTINGS_EDELIVERY.INSIGHT : USER_SETTINGS_EDELIVERY.CP
    const routeParams = this.isInsight ? { cid: this.client.cid || this.$state.params.cid } : undefined

    this.googleAnalyticsService.send(userSettings.event, 'click')

    this.$state.go(userSettings.route, routeParams)
  }

  get isInsight(): boolean {
    return this.configService.serverMode === 'agent'
  }

  get clientCanManageDeliveryPreferences(): boolean {
    return this.client?.roles?.some((role) => ALLOWED_PAPERLESS_DELIVERY_ROLES.some((allowedRole) => allowedRole.tc === role.tc))
  }

  get deliveryPreferencesFeatureEnabled(): boolean {
    return this.configService?.features.showEDelivery
  }

  get allowDeliveryPreferences(): boolean {
    if (!this.deliveryPreferencesFeatureEnabled) {
      return false
    }

    if (this.isInsight) {
      return this.client?.registered
    }

    return this.clientCanManageDeliveryPreferences
  }

}
