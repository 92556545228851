import { FilterFactory, IHttpService, IPromise, IQService } from "angular"
import { IEDeliveryOption } from "../user-settings/types"

export const ACTIVITY_TRACKING: string = 'activity-tracking'

export class CrafterService {
  static $inject = ['$http', '$q']

  metadata: any = {}
  getBeneficiaryMetadata: Function = () => this.getMetadata('beneficiaries', true)
  getHtmlText: Function = () => this.getGenericContent('metadata', 'html-text', false).then((result) => {
    return Object.entries(result).reduce((acc, item) => {
      const [key, value] = item
      const newValue: any = value

      if (newValue.listData) {
        newValue.text = newValue.listData

        delete newValue.listData
      }

      acc[key] = newValue

      return acc
    }, {})
  })
  getContactMetadata: Function = () => this.getMetadata('contact-us', false).then((result) => {
    return result.data.sort((a, b) => {
      let nameA = a.name.toLowerCase()
      let nameB = b.name.toLowerCase()

      if (nameA === 'Financial Professional'.toLowerCase()) {
        return -1
      }

      if (nameB === 'Financial Professional'.toLowerCase()) {
        return 1
      }

      return nameA.localeCompare(nameB)
    }).map((item: any, index: number) => {
      return { ...item, index, selected: false }
    })
  })
  getActivityDescriptors: Function = () => this.getContent(ACTIVITY_TRACKING).then((result: any) => {
    this.metadata[ACTIVITY_TRACKING] = result || {}

    return this.metadata[ACTIVITY_TRACKING]
  })
  getAdblockerBait: Function = () => this.getMetadata('adblocker', true)
  getEDeliveryMetaData: Function = () => this.getMetadata('e-delivery', false).then((result) => {
    return result.data.map((option: any): IEDeliveryOption => {
      return {
        heading: String(option.heading),
        description: String(option.description),
        order: Number.parseInt(option.order, 10),
        identifier: String(option.identifier),
        subheading: option.subheading ? String(option.subheading) : '',
        userSettingID: String(option.userSettingID),
      } as IEDeliveryOption
    }).sort((a: IEDeliveryOption, b: IEDeliveryOption) => {
      if (a.order < b.order) return -1
      if (a.order > b.order) return 1

      return 0
    })
  })
  getEPayMetadata: Function = () => this.getGenericContent('metadata', 'e-pay', false).then((result) => {
    // {
    //   "ach-limits": {
    //     "visible": true,
    //       "keyValuePairs": [
    //         {
    //           "key": "min",
    //           "value": "0.01"
    //         },
    //         {
    //           "key": "max",
    //           "value": "10000000"
    //         }
    //       ]
    //   }
    // }
    const rawAchLimits: any = result['ach-limits']
    const achLimits: any = {
      [rawAchLimits.keyValuePairs[0].key]: Number(rawAchLimits.keyValuePairs[0].value),
      [rawAchLimits.keyValuePairs[1].key]: Number(rawAchLimits.keyValuePairs[1].value),
    }

    return { achLimits }
  })

  constructor(private $http: IHttpService, private $q: IQService) { }

  getMetadata(type: string, useListItemHandler: boolean): any {
    const url = `/crafter/metadata/${type}`

    if (!this.metadata[type]) {
      return this.$http.get(url)
        .then((response: any) => {
          if (useListItemHandler) {
            this.metadata[type] = response.data.reduce((acc, item) => {
              acc[item.identifier] = item.listData.split('\n')

              return acc
            }, {})

            return this.metadata[type]
          }

          console.log(this.metadata[type])
          return response
        })
        .catch((err) => {
          console.error(err)

          return []
        })
    }

    return this.$q.resolve(this.metadata[type])
  }

  getContent(type: string, cache: boolean = false): any {
    const url = `/crafter/common-content/${type}`

    if (!this.metadata[type]) {
      return this.$http.get(url)
        .then((response: any) => {
          if (cache) {
            this.metadata[type] = response.data
          }

          return response.data
        })
        .catch((err) => {
          console.error(err)

          return null
        })
    }

    return this.$q.resolve(this.metadata[type])
  }

  getGenericContent(root: string, type: string, useListItemHandler: boolean): any {
    const url = `/crafter/generic-content/${root}/${type}`

    if (!this.metadata[type]) {
      return this.$http.get(url)
        .then((response: any) => {
          if (useListItemHandler) {
            this.metadata[type] = response.data.reduce((acc, item) => {
              acc[item.identifier] = item.listData.split('\n')

              return acc
            }, {})

            return this.metadata[type]
          }
          this.metadata[type] = response.data

          return this.metadata[type]
        })
        .catch((err) => {
          console.error(err)

          return []
        })
    }

    return this.$q.resolve(this.metadata[type])
  }
}
