export {appRun}

appRun.$inject = ['routerHelper', 'CONSTANTS']

/* @ngInject */
function appRun(routerHelper, CONSTANTS) {
  routerHelper.configureStates(getStates(CONSTANTS))
}

function setClientStatusTitle($rootScope) {
  $rootScope.state = 'epay'
  $rootScope.pageTitle = 'Payments'
  $rootScope.mobileHeaderTitle = 'Payments'
  $rootScope.isPopupVisible = false
}

function getStates(CONSTANTS) {
  return [
    {
      state: CONSTANTS.states.EPAY,
      config: {
        abstract: true,
        url: '/client/epay',
        templateUrl: 'app/client/epay/epay-view.html',
        clientAppOnly: true,
        data: {
          secure: true,
        },
        resolve: {
          ePayMetadata: [(ePayMetadata) => ePayMetadata],
        },
        onEnter: ['$rootScope', function($rootScope) {
          $rootScope.hideBarHeader = false
          setClientStatusTitle($rootScope)
          console.log('Entering epay parent state')
        }],
        onExit: ['$rootScope', function($rootScope) {
          console.log('Exiting epay parent state')
        }],
      },
    },
    {
      state: CONSTANTS.states.EPAY_HOME,
      config: {
        url: '/home',
        template: '<h3>epay.home</h3>',
        clientAppOnly: true,
        onEnter: ['$rootScope', function($rootScope) {
          $rootScope.state = CONSTANTS.states.EPAY_HOME
          $rootScope.pageTitle = 'Payment Home'
          console.log('Entering EpayController as epay.home')
        }],
      },
    },
    {
      state: CONSTANTS.states.EPAY_POLICY,
      config: {
        abstract: true,
        url: '/:id/:cmd',
        params: {
          cmd: {
            squash: true,
            value: null,
          },
        },
        templateUrl: 'app/client/epay/epay-view.html',
        clientAppOnly: true,
        data: {
          secure: true,
        },
        resolve: {
          canPayResult: ['$stateParams', 'policyService', '$location', function ($stateParams, policyService, $location) {
            const bypassSign = $stateParams.cmd === 'bypassSign'

            return policyService.getPolicyCanPay($stateParams.id, bypassSign)
              .catch(error => {
                if (error.status === 401) {
                  return $location.path('/unauthorized-policy-payment')
                }

                return error
              })
          }],
          policy: ['$stateParams', 'policyService',
            function ($stateParams, policyService) {
              const id = $stateParams.id
              const bypassSign = $stateParams.cmd === 'bypassSign'

              return policyService.getPolicySummary(id, CONSTANTS.policySource.pending, true, bypassSign).then(function (r) {
                if (r.error !== '') {
                  r.insureds = []
                  r.owners = []
                  return r
                }
                return r.policy
              }).catch(e => e)
            }],
          paymentDetails: ['$stateParams', 'policyService',
            function ($stateParams, policyService) {
              const id = $stateParams.id
              const bypassSign = $stateParams.cmd === 'bypassSign'

              return policyService.getPolicyPaymentDetails(id, bypassSign).then(function (r) {
                if (r.error) {
                  r.payments = []
                  r.paymentMode = {}
                  r.paymentMethod = {}
                  return r
                }

                return r.policyPaymentDetails
              }).catch(e => e)
            }],
            ePayMetadata: ['crafterService', (crafterService) => crafterService.getEPayMetadata()],
          /**
           * Moved to the child routes so that going backwards will refresh
           * the existing payment accounts dropdown
           *
           */
          // getPaymentAccounts: ['clientService',
          //   function(clientService) {
          //     return clientService.getPaymentAccounts(true)
          //   }],
        },
        onEnter: ['$rootScope', function ($rootScope) {
          $rootScope.hideBarHeader = false
          setClientStatusTitle($rootScope)
          console.log('Entering epay.policy abstract state')
        }],
      },
    },

    {
      state: CONSTANTS.states.EPAY_POSTBACK,
      config: {
        abstract: false,
        url: '/postback',
        params: {
          cmd: {
            squash: true,
            value: null,
          },
        },
        templateUrl: 'app/client/epay/paymentus-postback.html',
        clientAppOnly: true,
        data: {
          secure: true,
        },
        resolve: {},
        onEnter: ['$rootScope', function($rootScope) {
          // $rootScope.hideBarHeader = false
          // setClientStatusTitle($rootScope)
          console.log('Entering paymentus-postback')
        }],
      },
    },
    {
      state: CONSTANTS.states.EPAY_POLICY_PAYMENT,
      config: {
        url: '/payment',
        templateUrl: 'app/client/epay/epay-payment-view.html',
        controller: 'EpayController',
        controllerAs: 'epayCtrl',
        clientAppOnly: true,
        resolve: {
          enableCreditCards: ['configService', (configService) => configService.features.enableCreditCards],
          getPaymentAccounts: ['clientService', 'enableCreditCards', (clientService, enableCreditCards) => clientService.getPaymentAccounts(enableCreditCards)],
          ePayMetadata: (ePayMetadata) => ePayMetadata,
        },
        onEnter: ['$rootScope', function($rootScope) {
          $rootScope.state = CONSTANTS.states.EPAY_POLICY_PAYMENT
          $rootScope.pageTitle = 'Payment Entry'
          console.log('Entering EpayController as epay.policy.payment')
        }],
      },
    },
    {
      state: CONSTANTS.states.EPAY_POLICY_REVIEW,
      config: {
        url: '/review',
        templateUrl: 'app/client/epay/epay-review-view.html',
        controller: 'EpayController',
        controllerAs: 'epayCtrl',
        clientAppOnly: true,
        onEnter: ['$rootScope', function($rootScope) {
          $rootScope.state = CONSTANTS.states.EPAY_POLICY_REVIEW
          $rootScope.pageTitle = 'Payment Review'
          console.log('Entering EpayController as epay.policy.review')
        }],
        resolve: {
          getPaymentAccounts: () => { return {data: []} },
          ePayMetadata: (ePayMetadata) => ePayMetadata,
        },
      },
    },
    {
      state: CONSTANTS.states.EPAY_POLICY_CONFIRMATION,
      config: {
        url: '/confirmation',
        templateUrl: 'app/client/epay/epay-confirmation-view.html',
        controller: 'EpayController',
        controllerAs: 'epayCtrl',
        clientAppOnly: true,
        params: {
          bypassSign: true,
        },
        resolve: {
          getPaymentAccounts: () => { return {data: []} },
          ePayMetadata: (ePayMetadata) => ePayMetadata,
        },
        onEnter: ['$rootScope', function($rootScope) {
          $rootScope.state = CONSTANTS.states.EPAY_POLICY_CONFIRMATION
          $rootScope.pageTitle = 'Payment Confirmation'
          console.log('Entering EpayController as epay.policy.confirmation')
        }],
      },
    },
    {
      state: CONSTANTS.states.EPAY_POLICY_FAILURE,
      config: {
        url: '/failure/:errorCode',
        templateUrl: 'app/client/epay/epay-failure-view.html',
        controller: 'EpayController',
        controllerAs: 'epayCtrl',
        clientAppOnly: true,
        onEnter: ['$rootScope', function($rootScope) {
          $rootScope.state = CONSTANTS.states.EPAY_POLICY_FAILURE
          $rootScope.pageTitle = 'Payment Failure'
          console.log('Entering EpayController as epay.policy.failure')
        }],
        resolve: {
          getPaymentAccounts: () => { return {data: []} },
        },
      },
    },
  ]
}
