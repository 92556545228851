const CP_CONSTANTS = {
  accountTypeOptions: [
    {
      name: 'C',
      display: 'Checking',
    },
    {
      name: 'S',
      display: 'Savings',
    },
  ],
}

const ACH_LIMITS: any = {
  MIN: 0.01,
  MAX: 10000000,
}

export { CP_CONSTANTS, ACH_LIMITS }
