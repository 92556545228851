const numberStringMap = {
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
  8: 'eight',
  9: 'nine',
  10: 'ten',
}

emDash.$inject = ['CONSTANTS', 'utils']

  /* @ngInject */
function emDash(CONSTANTS, utils) {
  return function(item, requestsReturned) {
    if (!requestsReturned) {
      requestsReturned = true
    }
    return utils.isNotAvailable(item) ? CONSTANTS.emDash : item
  }
}

notAvailable.$inject = ['CONSTANTS', 'utils']

  /* @ngInject */
function notAvailable(CONSTANTS, utils) {
  return function(item, requestsReturned) {
    if (!requestsReturned) {
      requestsReturned = true
    }
    return utils.isNotAvailable(item) ? (requestsReturned ? CONSTANTS.notAvailableInfo : '') : item
  }
}

notAvailableTable.$inject = ['CONSTANTS', 'utils']

  /* @ngInject */
function notAvailableTable(CONSTANTS, utils) {
  return function(item, requestsReturned) {
    if (!requestsReturned) {
      requestsReturned = true
    }
    return utils.isNotAvailable(item) ? (requestsReturned ? ('*' + CONSTANTS.notAvailableInfo) : '') : item
  }
}

notApply.$inject = ['CONSTANTS', 'utils']

  /* @ngInject */
function notApply(CONSTANTS, utils) {
  return function(item) {
    return utils.isNotAvailable(item) ? CONSTANTS.notApply : item
  }
}

function none() {
  return function (item) {
    return item === undefined || item === '' ? 'None' : item
  }
}

function yesNo() {
  return function(bool) {
    return bool ? 'Yes' : 'No'
  }
}

function negativeParenthesis() {
  return function(input) {
    if (isNaN(input)) return input

    return input < 0 ? `(${Math.abs(input)})` : input
  }
}

zeroDefault.$inject = ['utils']

  /* @ngInject */
function zeroDefault(utils) {
  return function(val) {
    return utils.isNotAvailable(val) ? 0 : val
  }
}

function percentOrValue() {
  return function(val, isPct) {
    return isPct ? val + '%' : val
  }
}

percentOrDough.$inject = ['$filter']

function percentOrDough($filter) {
  return (val, isPct, decimals = 0) => isPct ? $filter('percent')(val, decimals) : $filter('currency')(val)
}

function percent() {
  return function(val, decimals, allowZeros = false) {
    let realNumber = (val !== undefined && val !== '' && allowZeros) || (!allowZeros && val) ? Number(val) : undefined

    if (realNumber === undefined) return
    if (decimals) {
      realNumber = realNumber.toFixed(decimals)
    }
    return realNumber + '%'
  }
}

nameCase.$inject = ['utils']

  /* @ngInject */
function nameCase(utils) {
  function dOrOApostropheCapitalize(match, capture1, capture2, offset, str) {
    return capture1 + "'" + capture2.toUpperCase()
  }

  function mcCapitalize(match, capture1, offset, str) {
    return 'Mc' + capture1.toUpperCase()
  }

  function capture1ToUpperCase(match, capture1) {
    return capture1.toUpperCase()
  }

  function capture1PlusCapture2ToUpperCase(match, capture1, capture2) {
    return capture1 + capture2.toUpperCase()
  }

  function lastSLowercase(match, capture1) {
    return match.toLowerCase()
  }

  return function(name) {
    if (typeof name === 'string' && name !== '') {
      return utils
          .capitalizeString(name)
          .replace(/Mc(\w)/g, mcCapitalize)
          .replace(/(D|O)'(\w)/g, dOrOApostropheCapitalize)
          .replace(/\b([iv]{2,})$/i, capture1ToUpperCase) // We only support Roman numerals consisting of "I" and "V"
          .replace(/^(.*)\b([iv]{2,})\b/i, capture1PlusCapture2ToUpperCase)
          .replace(/'\w*S\b/g, lastSLowercase)
    }
  }
}

function phoneFormat() {
  return function(tel) {
    let value, country, city, number

    if (!tel) {
      return ''
    }

    value = tel.toString().trim().replace(/^\+/, '')

    if (value.match(/[^0-9]/)) {
      return tel
    }

    switch (value.length) {
      case 10: // +1PPP####### -> C (PPP) ###-####
        country = 1
        city = value.slice(0, 3)
        number = value.slice(3)
        break

      case 11: // +CPPP####### -> CCC (PP) ###-####
        country = value[0]
        city = value.slice(1, 4)
        number = value.slice(4)
        break

      case 12: // +CCCPP####### -> CCC (PP) ###-####
        country = value.slice(0, 3)
        city = value.slice(3, 5)
        number = value.slice(5)
        break

      default:
        return tel
    }

    if (country === 1) {
      country = ''
    }

    number = number.slice(0, 3) + '-' + number.slice(3)

    return (country + ' (' + city + ') ' + number).trim()
  }
}

function inForceOverPending() {
  return function(items, client) {
    let newItems

    if (!angular.isArray(items)) return

    newItems = []

    function extractValueToCompare(item) {
      if (angular.isObject(item)) {
        return item.polNumber
      }
      return item
    }

    angular.forEach(items, function(item) {
      let isDuplicate = false
      let isActive = false
      let i = 0

      client.duplicatedPolicies = 0

      for (i = 0; i < newItems.length; i++) {
        if (angular.equals(extractValueToCompare(newItems[i]), extractValueToCompare(item))) {
          isDuplicate = true
          isActive = item.source === 'inforce' || item.source === 'rps'
          break
        }
      }

      if (isDuplicate) {
        if (isActive) {
          newItems[i] = item
          client.duplicatedPolicies += 1
        }
      } else {
        newItems.push(item)
      }
    })

    items = newItems
    return items
  }
}

changeDecimalToHalf.$inject = ['CONSTANTS']

  /* @ngInject */
function changeDecimalToHalf(CONSTANTS) {
  function hasDecimalAge(header) {
    const headersWithDecimalAge = [
      CONSTANTS.TABLE_HEADERS.LIFETIME.AGE,
      CONSTANTS.TABLE_HEADERS.LIFETIME.AGE_BAND,
    ]
    let i

    for (i in headersWithDecimalAge) {
      if (header === headersWithDecimalAge[i]) {
        return true
      }
    }

    return false
  }

  return function(val, columnHeader) {
    if (hasDecimalAge(columnHeader)) {
      val = val.replace(/\.5/g, '½')
    }
    val = val.replace(/ /g, ' ') // Change spaces to no-brake spaces
    return val
  }
}

dynamicFilter.$inject = ['$filter']

  /* @ngInject */
function dynamicFilter($filter) {
  return function(value, filterName) {
    let filterArr = [filterName]
    let hasFilter

    if (Boolean(filterName) && filterName.indexOf(':') !== -1) {
      filterArr = filterName.split(':')
    }

    hasFilter = filterArr[0] && angular.isString(filterArr[0])

    if (filterArr.length > 1) {
      return hasFilter ? $filter(filterArr[0])(value, filterArr.slice(1)) : value
    }
    return hasFilter ? $filter(filterArr[0])(value) : value
  }
}

function slice() {
  return function(arr, start, end) {
    return arr.slice(start, end)
  }
}

function isEmpty() {
  return function(input, defaultValue) {
    if (angular.isUndefined(input) || input === null || input === '') {
      return defaultValue
    }

    return input
  }
}

capitalize.$inject = ['utils']

function capitalize(utils) {
  return function(input) {
    return utils.capitalizeString(input)
  }
}

capitalizeWithOverrides.$inject = ['stringUtils']

function capitalizeWithOverrides(stringUtils) {
  return function(input, upperCaseExceptions) {
    if (upperCaseExceptions?.includes(input.toUpperCase())) {
      return input.toUpperCase()
    }
    return stringUtils.capitalizeString(input)
  }
}

function numberToLiteralString() {
  return function(total) {
    if (numberStringMap.hasOwnProperty(total)) {
      return numberStringMap[total]
    }

    return total.toString()
  }
}

function formatPolicyNumber() {
  return function(polNumber) {
    return polNumber ? polNumber.replace(/^(IND|PGA)/i, '') : ''
  }
}

function maskedSSN() {
  return function(ssn) {
    const ssnLastFour = ssn?.slice(5)

    return `XXX-XX-${ssnLastFour}`
  }
}

function maskedPhoneNumber() {
  return function(phoneNumber) {
    return phoneNumber.substr(phoneNumber.length - 4)
  }
}

function displayPhoneNumber() {
  return function(phoneNumberInfo) {
    const usPhoneFormatter = phoneFormat()

    if (phoneNumberInfo.countryCode === '1') {
      return usPhoneFormatter(phoneNumberInfo.phoneNumber)
    }

    return `+${phoneNumberInfo.countryCode} ${phoneNumberInfo.phoneNumber}`
  }
}

function displayMaskedPhoneNumber() {
  return function(phoneNumberInfo) {
    const usMaskPhoneNumber = maskedPhoneNumber()
    const last4 = usMaskPhoneNumber(phoneNumberInfo.phoneNumber)

    if (phoneNumberInfo.countryCode === '1') {
      return '(***) ***-' + last4
    }

    const stars = Object.assign([], phoneNumberInfo.phoneNumber.slice(0, phoneNumberInfo.phoneNumber.length - 4))
        .map(c => '*')
        .join('')

    return `+${phoneNumberInfo.countryCode} ${stars}${last4}`
  }
}

  /**
   * Format bytes as human-readable text.
   *
   * @param bytes Number of bytes.
   * @param si True to use metric (SI) units, aka powers of 1000. False to use
   *           binary (IEC), aka powers of 1024.
   * @param dp Number of decimal places to display.
   *
   * @return Formatted string.
   */
function humanizeBytes() {
  return function(bytes, si = true, dp = 1) {
    const thresh = si ? 1000 : 1024

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B'
    }

    const units = si
      ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    let u = -1
    const r = 10 ** dp

    do {
      bytes /= thresh
      ++u
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

    return bytes.toFixed(dp) + ' ' + units[u]
  }
}
export { notAvailable, notAvailableTable, emDash, phoneFormat, yesNo, negativeParenthesis, zeroDefault, percentOrValue, percentOrDough, percent, nameCase, inForceOverPending}
export {changeDecimalToHalf, dynamicFilter, slice, isEmpty, capitalize, capitalizeWithOverrides, numberToLiteralString}
export { formatPolicyNumber, maskedPhoneNumber, maskedSSN, displayPhoneNumber, displayMaskedPhoneNumber, humanizeBytes, none }
