import { IController } from 'angular'
import { AdBlockDetectorService } from './adblock-detector.service'

class AdblockDetectorController implements IController {
  static $inject = ['$scope', 'adBlockDetectorService', 'notificationCenterService', 'CONSTANTS']

  adBlockWarning: boolean

  constructor(private $scope: any, private adBlockDetectorService: AdBlockDetectorService, private notificationCenterService: any, private CONSTANTS: any) {}


  $onInit(): void {
    if (!this.adBlockDetectorService.isBrowser()) {
      console.error('Detection on server side is not supported. Please use library only on client side.')

      return
    }

    this.$scope.$applyAsync(() => {
      void this.adBlockDetectorService.init()
        .then(() => {
          const dataContainer = document.createElement('div')

          dataContainer.innerHTML = this.adBlockDetectorService.generatesBannersString()
          document.body.append(dataContainer)
          this.adBlockWarning = this.adBlockDetectorService.detect(document)
          console.log('adBlockWarning', this.adBlockWarning)
          if (this.adBlockWarning) {
            this.notificationCenterService.add(this.CONSTANTS.eventType.danger, 'You are seeing this message because you may have an ad-blocker enabled. Please disable your ad-blocker and refresh this page.', this.CONSTANTS.notificationType.systemUnavailability, this.CONSTANTS.eventId.SYSTEM, {
              status: 'permanent',
              top: true,
            })
          }
        })
    })
  }
}

export const AdblockDetectorComponentConfig = {
  template: '<div></div>',
  controller: AdblockDetectorController,
  controllerAs: 'adblockDetectorCtrl',
  // bindings: {
  //   resolve: '<',
  // },
}
