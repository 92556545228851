import { epayErrorKeys } from "./epay-message-resovler"

export {EpayController}
export const INITIAL_PAYMENT_CC_LIMIT = 10000

EpayController.$inject = [
  '$rootScope', '$scope', '$state', '$stateParams', 'CONSTANTS', 'policy',
  'paymentDetails', 'policyUtils', 'epayService', 'policyService', 'correspondenceService',
  'stringUtils', 'utils', 'canPayResult', '$filter', 'clientService',
  'epayUIState', 'getPaymentAccounts', 'ePayMetadata']

/* @ngInject */
function EpayController($rootScope, $scope, $state, $stateParams, CONSTANTS, policy,
  paymentDetails, policyUtils, epayService, policyService, correspondenceService,
  stringUtils, utils, canPayResult, $filter, clientService,
  epayUIState, getPaymentAccounts, ePayMetadata) {
  const self = this
  const policyId = policy.polNumber

  self.CONSTANTS = CONSTANTS

  self.id = $stateParams.id

  self.policyPages = {}
  self.formData = {}
  self.accounts = getPaymentAccounts.data || []
  self.selectedPaymentAccountId = ''
  self.bankAccountTypes = ['Checking', 'Savings']

  /** * PennCheck ***/
  self.BIN_TYPE_CONVERSIONS = {
    CC: {value: 'Credit', tc: '3'},
    PC: {value: 'Credit', tc: '3'},
    DC: {value: 'Debit', tc: '4'},
  }
  self.isPennCheck = false
  self.useForPennCheckInitialPayment = false
  self.bankingInfoReady = false
  self.initialPaymentAccountCompleted = false
  self.initialPaymentAccountMessage = ''
  self.hideNickname = false
  self.disablePennCheckContinue = true
  self.showInitialPaymentSection = false
  self.paymentErrorDisplayMessage = ''
  self.totalOverAchMax = false
  self.achMax = ePayMetadata.achLimits.max

  self.convertPaymentusAccountToCsAccount = (paymentusAccountObj) => {
    const category = {value: 'Card', tc: '3'}
    const type = self.BIN_TYPE_CONVERSIONS.CC
    const csPaymentAccountObj = {
      category,
      type,
      accountId: paymentusAccountObj.Token,
      accountNumberLast4: paymentusAccountObj.MaskedAccountNumber,
      cardHolderName: paymentusAccountObj.CardHolderName,
      defaultAccount: paymentusAccountObj.Default,
      expirationDate: paymentusAccountObj.ExpiryDate,
      // nickname: paymentusAccountObj.nickname,
      creditCardType: paymentusAccountObj.Type,
    }

    return csPaymentAccountObj
  }
  self.paymentFormStateChange = (isReady) => {
    console.log('isReady', isReady)
    self.bankingInfoReady = isReady
  }
  self.pennCheckContinue = () => {
    if (!self.initialPaymentAccountCompleted && self.selectedPaymentAccountId) {
      self.saveBankingInfo(self._selectedSavedAccount(self.accounts, self.selectedPaymentAccountId), 'inline', 'initialPaymentAccount', true)
      self.formData.savedAccount = true
    } else {
      epayService.savePaymentInfo(policyId, self.formData)
      $state.go(CONSTANTS.states.EPAY_POLICY_REVIEW, {id: $stateParams.id})
    }
  }
  self.saveBankingInfo = (bankingInfo, source, saveAs, continueAfterSave) => {
    const newAccount = source === 'ccModal' ? self.convertPaymentusAccountToCsAccount(bankingInfo) : bankingInfo

    // self.formData.isSavedAccount = source === 'ccModal' || source === 'bankingInfoModal'
    self.formData = Object.assign(self.formData, {[saveAs]: newAccount})
    epayService.savePaymentInfo(policyId, self.formData)
    self.initialPaymentAccountCompleted = true
    self.initialPaymentAccountMessage = self.resolveSelectedInitialPaymentMessage(newAccount, source)
    self.disablePennCheckContinue = !self.bankingInfoReady || !self.initialPaymentAccountCompleted

    if (continueAfterSave) {
      $state.go(CONSTANTS.states.EPAY_POLICY_REVIEW, {id: $stateParams.id})
    }
  }
  self.undoInitialPayment = () => {
    self.initialPaymentAccountCompleted = false

    // TODO: removePayment from formData.
  }

  self.resolveSelectedInitialPaymentMessage = (accountInfo, source) => {
    if (source === 'ccModal') {
      return 'Card ending in ' + accountInfo.accountNumberLast4
    }

    // source === 'bankInfoModal'
    return accountInfo.accountType?.value + ' account ending in ' + accountInfo.accountNumber?.slice(-4)
  }
  /** ****************/

  // eslint-disable-next-line no-unused-expressions
  self.canPayErrors = {
    '1': {warningOnly: false, text: 'This policy has already been paid.'},
  }

  self.canPayWarnings = {
    '27': {
      warningOnly: true,
      text: 'This policy is a 1035 exchange. Please contact your Financial Professional for information regarding your initial payment.',
    },
  }

  self.accountTypeOptions = [
    {
      name: 'C',
      display: 'Checking',
    },
    {
      name: 'S',
      display: 'Savings',
    },
  ]

  // Deprecated codes
  // const tryAgainErrorCodes = ['R038', 'R039', 'R070', 'R383', 'R362', 'R363', 'R378', 'AN20', 'R999']
  // const declinedCodes = ['1012300001', '1012300002', '1012300003']

  const tryAgainErrorCodes = [
    epayErrorKeys.PM_ACCOUNT_NUMBER_INVALID,
    epayErrorKeys.PM_BANK_INFO_VALIDATION_FAILED,
    epayErrorKeys.PM_BANK_INFO_INVALID_DATA,
    ]
  const declinedCodes = [
    epayErrorKeys.PM_CREDIT_CARD_NUMBER_INVALID,
    epayErrorKeys.PM_CREDIT_CARD_VERIFICATION_LENGTH,
    epayErrorKeys.PM_CREDIT_CARD_VERIFICATION_INVALID,
    epayErrorKeys.PM_EXPIRY_DATE_INVALID,
    epayErrorKeys.PM_EXPIRY_DATE_PAST,
  ]

  self.submitInProgress = false
  self.pennCheckDates = self.CONSTANTS.PENNCHECK_WITHDRAWAL_DATES

  self.epayService = epayService
  self.showCorrespondence = correspondenceService.showCorrespondence // direct function call to existing function in the service
  self.getClassByType = correspondenceService.getClassByType

  self.paymentDetailsStatus = paymentDetails.status
  self.errorMessage = canPayResult.error || ''
  self.warningMessage = ''

  self._getMinAmount = function(payment) {
    if (payment && payment[0]) {
      return payment[0].minimumAmount
    }
    return null
  }

  self._getMaxAmount = function(payment) {
    if (payment && payment[0]) {
      return payment[0].maximumAmount
    }
    return null
  }

  self._getAmount = function(payment) {
    if (payment && payment[0] && !payment[0].conversionCreditAmt) {
      return payment[0].amount
    } else if (payment && payment[0] && payment[0].conversionCreditAmt) {
      return payment[0].baseAmount
    }
    return null
  }

  self._makePaymentSuccess = function(data) {
    let errorCode

    // When the confirmation number is missing make the resonse look like a 500 error. This will trigger the error viewState.
    if (!data.error && !data.errorMessage && !data.data?.confirmationNumber) {
      data.status = 500
      data.errorMessage = { code: 'H500', description: 'Confirmation number does not exists.'}
    }

    if (!data.error && !data.errorMessage) { // have to cover both cases to get both JP Morgan and Server errors
      if (window.dataLayer) {
        dataLayer.push({
          event: 'epay-payment-success',
          policyId: policyId,
        })
      }
      epayService.mergeConfirmationInfo(policyId, data.data)
      $rootScope.$broadcast(CONSTANTS.events.REFRESH_PAYMENT_METHODS)
      self.submitInProgress = false
      $state.go(CONSTANTS.states.EPAY_POLICY_CONFIRMATION, {id: $stateParams.id})
    } else {
      if (data.errorMessage) {
        errorCode = data.errorMessage.code
      }
      errorCode = errorCode || 'H500'

      if (window.dataLayer) {
        dataLayer.push({
          event: 'epay-payment-failure',
          errorCode: errorCode,
          policyId: policyId,
        })
      }
      epayService.mergeConfirmationInfo(policyId, {errorMessage: data.errorMessage || data.error})
      self.submitInProgress = false
      $state.go(CONSTANTS.states.EPAY_POLICY_FAILURE, {id: $stateParams.id, errorCode: errorCode})
    }
  }

  self._setPolicyPages = function(data) {
    const policyPagesList = data.documents.map(function(doc) {
      doc.modDate = new Date(doc.modDate)
      return doc
    })
      .sort(function(a, b) {
        if (a.modDate < b.modDate) {
          return 1
        }
        if (a.modDate > b.modDate) {
          return -1
        }
        return 0
      })

    if (policyPagesList.length) {
      self.policyPages = policyPagesList[0]
    }
  }

  // DEPRICATED
  self.saveAndContinue = function(bankingInfo, section) {
    if (section === 'modal') {
      self.formData = Object.assign(self.formData, bankingInfo)
    } else {
      self.formData = Object.assign(self.formData, {initialPaymentAccount: self._selectedSavedAccount(self.accounts, self.selectedPaymentAccountId)})
      self.formData.savedAccount = true
      self.formData.saveAccountInfo = false
    }
    epayService.savePaymentInfo(policyId, self.formData)
    $state.go(CONSTANTS.states.EPAY_POLICY_REVIEW, {id: $stateParams.id})
  }

  self.saveAndGoBack = function() {
    $state.go(CONSTANTS.states.EPAY_POLICY_PAYMENT, {id: $stateParams.id})
  }

  self.submitPayment = function() {
    self.submitInProgress = true
    epayService.savePaymentInfo(policyId, self.formData)
    policyService.makeInitialPayment(self.id, self.formData).then(self._makePaymentSuccess)
    utils.windowDataLayerPush(window, 'epay-initial-submission', '', {policyId: policyId, amount: self.totalPayment, termConversion: self.conversionCreditType !== null}, '')
  }

  self._calculateTotalPayment = function(initialPaymentAmount, initialPaymentDueAmount, conversionCreditType, isTraditional, additionsPaymentAmount) {
    const temp = additionsPaymentAmount || 0

    if (conversionCreditType) {
      return initialPaymentDueAmount + (isTraditional ? temp : 0)
    }

    return initialPaymentAmount + (isTraditional ? temp : 0)
  }

  self._termConversionNotPayableMessage = function(totalPayment, isInitialPayment) {
    if ((totalPayment <= 0) && isInitialPayment) {
      return 'There is no payment due for this policy. Please contact Client Services at 1-800-523-0650. If your policy was issued in New York, please call 1-855-446-7393. (Conversion credit).'
    }

    return ''
  }

  // State of Initial Payment amount

  self.isPaymentDefault = function() {
    return self.formData.initialPaymentAmount === self.formData.initialPaymentDefault
  }

  self.isPaymentValid = function() { // valid but not recommended
    return self.isPaymentDefault() ||
      (!self.isPaymentAboveMax() &&
        !self.isPaymentBelowMin())
  }

  self.isPaymentBelowMin = function() {
    return self.formData.initialPaymentAmount < self.formData.initialPaymentMin
  }

  self.isPaymentAboveMax = function() {
    return self.formData.initialPaymentAmount > self.formData.initialPaymentMax
  }

  // State of PUA amount

  self.hasPaymentAdditions = function() {
    return self.additionsPaymentDue && self.additionsPaymentDue.length > 0
  }

  self.isPaymentAdditionsDefault = function() {
    return self.formData.additionsPaymentAmount === self.formData.additionsPaymentDefault
  }

  self.isPaymentAdditionsValid = function() {
    return self.isPaymentAdditionsDefault() ||
      (!self.isPaymentAdditionsAboveMax() &&
        !self.isPaymentAdditionsBelowMin())
  }

  self.isPaymentAdditionsBelowMin = function() {
    return self.formData.additionsPaymentAmount < self.formData.additionsPaymentMin && self.formData.additionsPaymentAmount !== 0
  }

  self.isPaymentAdditionsAboveMax = function() {
    return self.formData.additionsPaymentAmount > self.formData.additionsPaymentMax
  }

  self.showNextPaymentDateOnPostConfirm = function() {
    return !self.isPennCheckMode() || !policyUtils.isUniversalLifePolicy(self.policy.productType)
  }

  self.isPennCheckMode = function() {
    return self.formData.paymentModeCode === CONSTANTS.PAYMENT_MODE_TC.monthly && self.formData.paymentMethodCode === CONSTANTS.paymentMethods.electronicFundsTransfer.typeCode
  }

  self.getPennCheckDayString = function(tc) {
    const matches = CONSTANTS.PENNCHECK_WITHDRAWAL_DATES.filter(function(item) {
      return item.dom === tc
    })

    if (matches && matches.length) {
      return matches[0].date
    }
    return ''
  }

  self.showTryAgain = function() {
    return tryAgainErrorCodes.includes(self.formData.errorCode)
  }

  self.showContactUsHelp = () => {
    return declinedCodes.includes(self.formData.errorCode)
  }

  self._processPaymentOption = function(paymentOption) {
    if (self.canPayErrors[paymentOption.reason.tc]) {
      return {translated: true, isError: true, text: self.canPayErrors[paymentOption.reason.tc].text}
    } else if (self.canPayWarnings[paymentOption.reason.tc]) {
      return {translated: true, isError: false, text: self.canPayWarnings[paymentOption.reason.tc].text}
    }
    return {translated: false, isError: true, text: paymentOption.reason.value}
  }

  self._processErrorMessages = function(result) {
    const canPayData = result.data

    if (canPayData && canPayData.paymentOptions && canPayData.paymentOptions.every(isNotPayableWithReason)) {
      const reasons = canPayData.paymentOptions
        .map((paymentOption) => self._processPaymentOption(paymentOption))

      const errors = reasons.filter(err => err.isError).map(err => err.text)
      const warnings = reasons.filter(err => !err.isError).map(err => err.text)

      self.errorMessage = errors ? utils.dedupe(errors).join(' ') : result.error
      self.warningMessage = warnings ? utils.dedupe(warnings).join(' ') : ''
    }
    if (!self.errorMessage && result.error) {
      self.errorMessage = result.error
    }
  }

  self._canPolicyBePaid = function(canPayData, payments) {
    return canPayData && canPayData.canMakePayment && payments && payments.length
  }

  self._hasPaymentRange = function() {
    return self.formData.initialPaymentMin !== self.formData.initialPaymentMax
  }

  self._setPaymentPropertiesOrErrors = function() {
    if (paymentDetails.error) {
      self._showPaymentDetailsError()
    } else if (self._canPolicyBePaid(canPayResult.data, paymentDetails.payments)) {
      self.initialPaymentDue = paymentDetails.payments.filter(function(p) {
        return p.tc === CONSTANTS.PAYMENT_TYPES.INITIAL_PAYMENT
      })
      self.additionsPaymentDue = paymentDetails.payments.filter(function(p) {
        return p.tc === CONSTANTS.PAYMENT_TYPES.PURCHASE_PUA
      })
    } else {
      console.info('calling _processErrorMessages')
      self._processErrorMessages(canPayResult)
    }
  }

  self._setPolicyProperties = function() {
    self.policy = angular.merge({}, policy)
    self.isTraditional = policyUtils.isTraditional(policy.productType)
    self.insureds = self.policy.clients.insureds.map(function(i) {
      return i.fullName
    })
    self.owners = self.policy.owners.map(function(i) {
      return i.fullName
    })

    self.paymentDetails = angular.merge({}, paymentDetails) // full API results
    self.paymentDetails.paymentDueDate = Date.parse(paymentDetails.paymentDueDate) // changes it from string to actual date object

    self.paymentsDue = paymentDetails.payments // all payments
  }

  self._conversionCreditType = function(payments) {
    return payments.reduce(function(acc, payment) {
      if (payment.conversionCreditType && ['1', '2'].includes(payment.conversionCreditType.tc)) {
        acc = {
          conversionCreditAmt: payment.conversionCreditAmt,
          conversionCreditType: payment.conversionCreditType.value,
          conversionCreditTypeTc: payment.conversionCreditType.tc,
        }
      }

      return acc
    }, null)
  }

  self._conversionCreditTypeMessage = function(conversionCredit) {
    if (conversionCredit) {
      const total = $filter('currency')(conversionCredit.conversionCreditAmt)

      if (conversionCredit.conversionCreditTypeTc === '1') {
        return 'A Conversion Credit of ' + total + ' is being used to offset your premium payment.'
      } else if (conversionCredit.conversionCreditTypeTc === '2') {
        return 'Additional premium of ' + total + ' from a term conversion credit will be applied to this policy.'
      }
    }

    return ''
  }

  self._showPaymentDetailsError = function() {
    if (self.paymentDetailsStatus !== 200) {
      switch (self.paymentDetailsStatus) {
        case CONSTANTS.httpErrors.internalServer.typeCode:
          self.errorMessage = CONSTANTS.httpErrors.internalServer.description
          break
        case CONSTANTS.httpErrors.notFound.typeCode:
          self.errorMessage = CONSTANTS.httpErrors.notFound.description
          break
        default:
          self.errorMessage = CONSTANTS.httpErrors.default.description
      }
    }
  }

  self._showEffectiveDateIssueDateMessage = function() {
    return policy.effDate > policy.issueDate
  }

  self.setPaymentDate = function() {
    if (self._showEffectiveDateIssueDateMessage()) {
      self.formData.paymentDate = policy.effDate
    }
  }

  self._acceptedPaymentMethods = function() {
    const paymentMethods = paymentDetails.paymentMethods.paymentMethods || []

    return paymentMethods.map((paymentMethod) => {
      if (paymentMethod.usable === true) {
        return paymentMethod.type.value
      }
    })
  }

  self._defaultAccount = function(accounts) {
    return accounts.find((account) => account.defaultAccount === true)
  }

  self.organizePaymentAccounts = function(accounts) {
    self.accounts = self.filterSavedPaymentMethods(accounts, self._acceptedPaymentMethods())

    if (!$rootScope.featureFlags.enableCreditCards || ($rootScope.featureFlags.enableCreditCards && !self.allowCreditPayments)) {
      self.accounts = self.accounts.filter((account) => self.bankAccountTypes.includes(account?.type?.value))
    }

    if (self.accounts.length) {
      self.selectedPaymentAccountId = self._defaultAccount(accounts)?.accountId || accounts[0].accountId
    }
  }

  self.filterSavedPaymentMethods = function(accounts, acceptedPaymentMethods) {
    return accounts.filter((account) => {
      if (acceptedPaymentMethods.includes('ACH') && !acceptedPaymentMethods.includes('Credit') && !acceptedPaymentMethods.includes('Debit')) {
        return self.bankAccountTypes.includes(account?.type?.value)
      }

      return account
    })
  }

  self._selectedSavedAccount = function(accounts, selectedId) {
    let selectedAccount = {}

    accounts.forEach((account) => {
      if (account.accountId === selectedId) {
        selectedAccount = account
      }
    })
    return selectedAccount || {}
  }

  // DEPRECATED
  self.refreshAccounts = function(accounts, totalPayment) {
    if (!self.allowCreditPayments) {
      self.accounts = accounts.filter((account) => self.bankAccountTypes.includes(account?.type?.value))
    } else {
      self.accounts = getPaymentAccounts.data || []
      self.organizePaymentAccounts(self.accounts, self.totalPayment)
    }
  }

  self.puaBlur = function() {
    if (self.formData.additionsPaymentAmount === '') {
      self.formData.additionsPaymentAmount = 0
    }
  }

  self._allowCreditPayments = function(total) {
    return total <= INITIAL_PAYMENT_CC_LIMIT
  }

  self.isCreditDebitPayment = function(paymentMethod) {
    return paymentMethod === 'CC' || paymentMethod === 'DC' || paymentMethod === 'Credit' || paymentMethod === 'Debit'
  }

  self.isSavedBankAccountPayment = function(accountType, accountId, savedAccount) {
    return (accountType === 'Checking' || accountType === 'Savings') && accountId && savedAccount
  }

  self.isNewBankAccountPayment = function(accountType, accountId, savedAccount) {
    return (accountType === 'C' || accountType === 'S') && !accountId && !savedAccount
  }

  self.cardExpiration = function(date) {

    const month = date.slice(0, 2)
    const year = date.slice(-2)

    return `${month}/${year}`
  }

  self.isCardPayment = function(initialPaymentAccount) {
    return initialPaymentAccount.category?.value === 'Card'
  }

  function isNotPayableWithReason(paymentOption) {
    return !paymentOption.isPayable && paymentOption.reason
  }

  function _initialPaymentAmountExceedsLimit(paymentAmount, conversionCredit, limit) {
    const finalPaymentAmount = conversionCredit ? paymentAmount - conversionCredit : paymentAmount

    // console.log(paymentAmount, conversionCredit, limit, finalPaymentAmount)
    return finalPaymentAmount > limit
  }

  function _doesResolvedDataHave500Errors(canPayData, policyData, paymentDetailsData, getPaymentAccountsData) {
    return [canPayData, policyData, paymentDetailsData, getPaymentAccountsData]
      .reduce((acc, resultData) => {
        if (!acc && resultData?.status === 500) acc = true

        if (acc) self.errorMessage = 'Something unexpected happened. Please try again or contact support at 1-800-523-0650. Please call (855) 446-7393 if your policy was issued in New York.'

        return acc
      }, false)
  }

  function initPaymentView() {
    // Get new instance the PaymentModel
    self.formData = epayService.getNewPaymentModel()

    // Get accepted payment Methods from PaymentModel
    self.acceptedPaymentMethods = self._acceptedPaymentMethods()

    // Can credit or debit cards be used with this policy?
    self.useCreditCards = Boolean($rootScope.featureFlags.enableCreditCards) && (self.acceptedPaymentMethods.includes('Credit') || self.acceptedPaymentMethods.includes('Debit'))

    // Sace paymentDetails to the PaymentModel
    self.formData.bypassSign = $stateParams.cmd === 'bypassSign'
    self.formData.paymentMode = paymentDetails.paymentMode.value
    self.formData.paymentModeCode = paymentDetails.paymentMode.tc
    self.formData.paymentMethodCode = paymentDetails.paymentMethod.tc

    // Determine if this policy is using PennCheck
    self.isPennCheck = self.isPennCheckMode()

    // if there is conversionOffsetAmount then add it to the PaymentModel
    if (self.conversionOffsetAmount) {
      self.formData.conversionCreditOffsetAmt = self.conversionOffsetAmount
    }

    // DEPRECATED: this no longer required, it is now handled by the bankInfoForm component
    // if (paymentDetails.paymentMode.tc === CONSTANTS.PAYMENT_MODE_TC.monthly) {
    //   self.formData.pennCheckDate = CONSTANTS.PENNCHECK_WITHDRAWAL_DATES[0].dom
    //   self.formData.saveAccountInfo = true
    // }

    // Initial Payment Values
    self.formData.initialPaymentAmount = self._getAmount(self.initialPaymentDue)
    self.formData.initialPaymentDefault = self.formData.initialPaymentAmount
    self.formData.initialPaymentMin = self._getMinAmount(self.initialPaymentDue)
    self.formData.initialPaymentMax = self._getMaxAmount(self.initialPaymentDue)

    // Additions
    if (self.additionsPaymentDue && self.additionsPaymentDue.length > 0) {
      self.formData.additionsPaymentAmount = self._getAmount(self.additionsPaymentDue)
      self.additionsPaymentDefaultAmount = self._getAmount(self.additionsPaymentDue)
      self.formData.additionsPaymentDefault = self.formData.additionsPaymentAmount
      self.formData.additionsPaymentMin = self._getMinAmount(self.additionsPaymentDue)
      self.formData.additionsPaymentMax = self._getMaxAmount(self.additionsPaymentDue)
      self.formData.isTraditional = self.isTraditional
      self.formData.paymentAdditionsAllowedValues = {
        numbers: [0],
        ranges: [{
          maximum: self.formData.additionsPaymentMax,
          minimum: self.formData.additionsPaymentMin,
        }],
      }
    }

    self.setPaymentDate()

    self.conversionCreditTypeMessage = self._conversionCreditTypeMessage(self.conversionCreditType)
    self.formData.totalPayment = self._calculateTotalPayment(self.formData.initialPaymentAmount, self.initialPaymentDue[0].amount, self.conversionCreditType, self.isTraditional, self.formData.additionsPaymentAmount)
    self.allowCreditPayments = self._allowCreditPayments(self.formData.totalPayment)
    self.termConversionNotPayableMessage = self._termConversionNotPayableMessage(self.formData.totalPayment, self.initialPaymentDue.length > 0)

    if (self.accounts.length) {
      self.organizePaymentAccounts(self.accounts)
    }

    if (self.conversionCreditType && self.conversionCreditType.conversionCreditTypeTc === '1') {
      self.formData.conversionCreditAmt = self.conversionCreditType.conversionCreditAmt
    }

    // Resolve initial UI State for Payment view.
    self.paymentView = epayUIState.resolvePaymentView(self.isPennCheck, Boolean(self.accounts.length), self.useCreditCards, _initialPaymentAmountExceedsLimit(self.formData.initialPaymentAmount, self.formData.conversionCreditAmt, INITIAL_PAYMENT_CC_LIMIT))

    // Add achBankiingInfo to formData if the payment view UI state allows showing the ACH form.
    if (self.paymentView.showACHForm) {
      const inlinePaymentProperty = self.isPennCheck ? 'pennCheckPaymentAccount' : 'initialPaymentAccount'

      self.formData[inlinePaymentProperty] = {
        accountType: {
          tc: 'C',
          value: 'Checking',
        },
        saveAccountInfo: false,
      }

      if (self.isPennCheck) {
        self.formData[inlinePaymentProperty].pennCheckDate = '1' // Default to the 1st
      }
    }

    // console.log(self)
  }

  function initReviewView() {
    const paymentAccount = self.formData.initialPaymentAccount ?? self.formData.pennCheckPaymentAccount
    const accountId = true // You will always have an account.  Initial or penn check account.

    self.formData.initialPaymentAccount = paymentAccount

    self.reviewView = epayUIState.resolveReviewView(paymentAccount, self.formData.pennCheckPaymentAccount, accountId, self.isCardPayment(self.formData.initialPaymentAccount || self.formData.pennCheckPaymentAccount))
    // console.log(self.reviewView)
  }

  function initConfirmationView() {
    const bypassSign = $stateParams.bypassSign

    correspondenceService
      .getFilteredCorrespondences([policy.polNumber], [CONSTANTS.correspondenceDocTypes.policyPages.typeCode], bypassSign)
      .then(self._setPolicyPages)
  }

  function init() {
    const unrecoverableErrorState = _doesResolvedDataHave500Errors(canPayResult, policy, paymentDetails, getPaymentAccounts)

    if (!unrecoverableErrorState) {
      self._setPolicyProperties()
      self._setPaymentPropertiesOrErrors()

      self.servicePhoneNumber = self.policy.jurisdiction === 'New York' ? '(855) 446-7393' : '(800) 523-0650'
      self.showEffectiveDateIssueDateMessage = self._showEffectiveDateIssueDateMessage()
      self.conversionCreditType = self._conversionCreditType(self.paymentsDue)

      if ($state.is(CONSTANTS.states.EPAY_POLICY_PAYMENT)) {
        initPaymentView()
      } else {
        self.formData = epayService.getPaymentInfo(policyId)
        // console.log('REVIEW', self.formData)
      }

      if ($state.is(CONSTANTS.states.EPAY_POLICY_CONFIRMATION)) initConfirmationView()

      if ($state.is(CONSTANTS.states.EPAY_POLICY_REVIEW)) initReviewView()
    }

    if ($stateParams.errorCode) {
      self.formData.errorCode = $stateParams.errorCode
      self.paymentErrorDisplayMessage = epayService.getErrorDescriptions($stateParams.errorCode, policy.jurisdiction, policy.polNumber, 'initial-payment')
    }

    $scope.$watch('epayCtrl.formData.additionsPaymentAmount', function(n, o) {
      if (n !== undefined && n !== o) {
        self.formData.totalPayment = self._calculateTotalPayment(self.formData.initialPaymentAmount, self.initialPaymentDue[0].amount, self.conversionCreditType, self.isTraditional, self.formData.additionsPaymentAmount)
        self.allowCreditPayments = self._allowCreditPayments(self.formData.totalPayment)
        self.totalOverAchMax = self._calculateTotalPayment(self.formData.initialPaymentAmount, self.initialPaymentDue[0].amount, self.conversionCreditType, self.isTraditional, self.formData.additionsPaymentAmount) > self.achMax
      }
    })
  }

  init()
}