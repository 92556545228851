/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/prefer-readonly */
export class ClientPaymentController implements IClientPaymentState { // possible use of with() for this.formData

  canPay: { premium: boolean; initialPayment: boolean; pua: boolean; loan: boolean }
  showLoanUI: boolean
  closeWidget: any
  insureds: any[] = []
  owners: any[] = []
  paymentDetailsError: string
  paymentErrorDisplayMessage: string
  paymentDetailsStatus: string
  httpErrorMessage: string = ''
  initialPaymentDue: any[]
  additionsPaymentDue: any[]
  premiumPaymentDue: any[]
  loanPaymentDue: any[]
  isTraditional: boolean
  policy: any
  paymentDetails: any
  policyId: string
  id: string
  policySummary: any
  currentDate: Date = new Date()
  policyPages: any = {}
  formData: any = {}
  selectedPaymentAccountId: string | null
  paymentAccounts: any[] = []
  selected: any
  // premiumLabel: string
  // isPaymentDefault: boolean
  // hasPaymentDueOfType: boolean
  // isPennCheckMode: boolean
  pennCheckDatString: string
  latestBill: string
  isIE: boolean // Deprecated
  isPhone: boolean
  isMobileSafari: boolean
  isARealClient: any
  payoffQuote: any
  isAnniversaryBill: boolean = false
  loanInterest: any
  marketingName: string
  appliedInterest: number
  appliedPrincipal: number
  loanPrincipal: number
  anniversaryPaymentIsValid: boolean
  loanOnlyPayment: boolean
  payButtonEnabled: boolean
  submissionInProgress: boolean = false
  hasPayment: boolean
  policyIsUniversalLife: boolean
  policyIsUlOrIul: boolean
  policyIsVul: boolean
  hoUserCanPay: any
  autocomplete: string
  paymentForm: any
  mailingAddresses: any
  public isAMec: boolean
  public mecAmount: number
  ePayMetadata: any
  overPaymentMaxMessage: string

  static $injector: any = [
    '$rootScope',
    '$scope',
    '$stateParams',
    'CONSTANTS',
    'CP_CONSTANTS',
    'authService',
    'epayService',
    'utils',
    '$anchorScroll',
    '$location',
    'policyUtils',
    'policyService',
    'dateUtils',
    'correspondenceService',
    'clientService',
    'stateUtils',
    'paymentsDataProvider',
    'configService',
    '$filter',
    '$timeout',
    'companiesService',
  ]

  constructor(public $rootScope: any, private $scope: any, private $stateParams: any, private CONSTANTS: any, private CP_CONSTANTS: any, public authService: any,
    private epayService: any, public utils: any, private $anchorScroll: any, private $location: any, private policyUtils: any, private policyService: any,
    private dateUtils: any, private correspondenceService: any, private clientService: any, private stateUtils: any, public paymentsDataProvider: any,
    private configService: any, public $filter: any, private $timeout: any, private companiesService: any) {
    this.init()
    // this.isPremiumRowEnabled = this.configService?.features?.enableArbitraryULPayment
  }

  private _getMinAmount(payment: any[]): number | null {
    if (payment[0]) {
      return payment[0].minimumAmount
    }
    return null
  }

  _getMaxAmount(payment: any[]): number | null {
    if (payment[0]) {
      return payment[0].maximumAmount
    }
    return null
  }

  _getAmount(payment: any[]): number | null {
    if (payment[0]) {
      return payment[0].amount
    }
    return null
  }

  _processMakePaymentResults(data: any): void {
    let errorCode

    // When the confirmation number is missing make the resonse look like a 500 error. This will trigger the error viewState.
    if (!data.error && !data.errorMessage && !data.data?.confirmationNumber) {
      data.status = 500
      data.errorMessage = { code: 'H500', description: 'Confirmation number does not exists.' }
    }

    // have to cover both cases to get both JP Morgan and Server errors
    if (!data.error && !data.errorMessage) {
      this.epayService.mergeConfirmationInfo(this.policyId, data.data)
      // $state.go(CONSTANTS.states.EPAY_POLICY_CONFIRMATION, {id: $stateParams.id})
      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'payment-inforce-success',
          policyId: this.policyId,
          amount: this.calculateTotalPayment(),
        })
      }
      this.$rootScope.$broadcast(this.CONSTANTS.events.REFRESH_PAYMENT_METHODS)

      if (this.isPhone) {
        this.scrollToConfirmationOnMobile()
      }
    } else {
      if (data.errorMessage) {
        errorCode = data.errorMessage.code
        if (this.isPhone) {
          this.scrollToConfirmationOnMobile()
        }
      }
      errorCode = errorCode || 'H500'

      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'payment-inforce-failure',
          errorCode: errorCode,
          policyId: this.policyId,
        })
      }

      this.epayService.mergeConfirmationInfo(this.policyId, {
        errorMessage: data.errorMessage || data.error,
        errorCode: errorCode,
      })

      this.paymentErrorDisplayMessage = this.epayService.getErrorDescriptions(this.formData.errorCode, this.policy.jurisdiction, this.policy.polNumber, 'inforce-payment')
      // $state.go(CONSTANTS.states.EPAY_POLICY_FAILURE, {id: $stateParams.id, errorCode: errorCode})
    }

    this.formData = this.epayService.getPaymentInfo(this.policyId)

  }

  isUniversalLifePolicy() {
    return this.policySummary.productType === this.CONSTANTS.productType.universalLife ||
      this.policySummary.productType === this.CONSTANTS.productType.IUL ||
      this.policySummary.productType === this.CONSTANTS.productType.variableUniversalLife
  }

  isUlOrIul(): boolean {
    return this.policySummary.productType === this.CONSTANTS.productType.universalLife || this.policySummary.productType === this.CONSTANTS.productType.IUL
  }

  isVul(): boolean {
    return this.policySummary.productType === this.CONSTANTS.productType.variableUniversalLife
  }

  shouldShowForm(): boolean {
    return (this.formData.errorMessage === null || this.formData.errorMessage === undefined) && (this.formData.confirmationNumber === null || this.formData.confirmationNumber === undefined) && this.paymentDetails.totalAmount <= this.ePayMetadata.achLimits.max
  }

  shouldShowPaymentDisabled(): boolean {
    return this.paymentDetails.totalAmount > this.ePayMetadata.achLimits.max
  }

  shouldShowError() {
    return this.formData.errorMessage !== null && this.formData.errorMessage !== undefined
  }

  shouldShowConfirmation() {
    return this.formData.confirmationNumber !== null && this.formData.confirmationNumber !== undefined
  }

  private _periodLengthInMonths(paymentDetails): number | null {
    if (paymentDetails.billingModeInMonths) {
      return paymentDetails.billingModeInMonths
    }

    if (paymentDetails.paymentMode?.tc) {
      // converts TC of 1,2,3,4 into 12,6,3,1 month durations
      return [12, 6, 3, 1][parseInt(paymentDetails.paymentMode.tc, 10) - 1] || null
    }

    return null
  }

  premiumLabel(paymentDetails) {

    if (this.policyIsUniversalLife) {
      return 'Additional Premium'
    }

    // eslint-disable-next-line no-underscore-dangle
    const periodLengthInMonths = this._periodLengthInMonths(paymentDetails)

    if (periodLengthInMonths) {
      return 'Premium for ' + periodLengthInMonths + ' month' + (periodLengthInMonths === 1 ? '' : 's')
    }

    return 'Premium'
  }

  _paymentAccountOK(): boolean {
    // const paidNumber = Number(this.selectedPaymentAccountId)
    // const paidIsNumber = !Number.isNaN(paidNumber)
    const paymentOK = this.paymentForm && (this.selectedPaymentAccountId === 'CREATE' ? this.paymentForm.routingNumber && this.paymentForm.bankAccountNumber : Boolean(this.selectedPaymentAccountId))
    // console.log('paymentOK: %s, angular.isNumber(this.selectedPaymentAccountId): %s, this.selectedPaymentAccountId: %s, this.paymentForm.routingNumber: %s, this.paymentForm.bankAccountNumber: %s', paymentOK, angular.isNumber(this.selectedPaymentAccountId), this.selectedPaymentAccountId, this.paymentForm.routingNumber, this.paymentForm.bankAccountNumber)

    return paymentOK
  }

  _onSelectedPaymentAccountIdChange() {
    const paymentAccount = this.paymentAccounts.filter(account => account.accountId === this.selectedPaymentAccountId)[0]

    if (this.selectedPaymentAccountId === 'CREATE') {
      this.formData.accountType = this.CP_CONSTANTS.accountTypeOptions[0].name
    } else {
      this.formData.accountType = paymentAccount.accountType
    }

    if (this.paymentForm) {
      this.payButtonEnabled = this.paymentForm.$valid && this.formData.disclaimer && this.hasPayment && this._paymentAccountOK()
    }

    // console.log('paymentForm.$valid: %s, disclaimer: %s, paymentOK: %s', this.paymentForm && this.paymentForm.$valid, this.formData.disclaimer, this._paymentAccountOK())

  }

  clearAccountConfirm() {
    if ((this.paymentForm.bankAccountNumberConfirm?.$dirty) && (this.formData.accountNumber !== this.formData.accountNumberConfirm)) {
      this.formData.accountNumberConfirm = ''
    }
  }

  isPaymentDefault(type) {
    switch (type) {
      case this.CONSTANTS.PAYMENT_TYPES.PREMIUM:
        return this.formData.premiumPaymentAmount === this.formData.premiumPaymentDefault
      case this.CONSTANTS.PAYMENT_TYPES.PURCHASE_PUA:
        return this.formData.additionsPaymentAmount === this.formData.additionsPaymentDefault
      case this.CONSTANTS.PAYMENT_TYPES.LOAN:
        return this.formData.loanPaymentAmount === this.formData.loanPaymentDefault
      case this.CONSTANTS.PAYMENT_TYPES.INITIAL_PAYMENT:
        // default to initial payment for backwards compatibility
        return this.formData.initialPaymentAmount === this.formData.initialPaymentDefault
      default:
        return 'INVALID TYPE'
    }
  }

  isPaymentAboveMax(type) {
    switch (type) {
      case this.CONSTANTS.PAYMENT_TYPES.PREMIUM:
        return this.formData.premiumPaymentAmount > this.formData.premiumPaymentMax
      case this.CONSTANTS.PAYMENT_TYPES.PURCHASE_PUA:
        return this.formData.additionsPaymentAmount > this.formData.additionsPaymentMax
      case this.CONSTANTS.PAYMENT_TYPES.LOAN:
        return this.formData.loanPaymentAmount > this.formData.loanPaymentMax
      case this.CONSTANTS.PAYMENT_TYPES.INITIAL_PAYMENT:
        return this.formData.initialPaymentAmount > this.formData.initialPaymentMax
      default:
        return 'INVALID TYPE'
    }
  }

  isPaymentValid(type) { // valid but not recommended
    return this.isPaymentDefault(type) ||
      (!this.isPaymentAboveMax(type) &&
        !this.isPaymentBelowMin(type))
  }

  isPaymentBelowMin(type) {
    switch (type) {
      case this.CONSTANTS.PAYMENT_TYPES.PREMIUM:
        return this.formData.premiumPaymentAmount < this.formData.premiumPaymentMin
      case this.CONSTANTS.PAYMENT_TYPES.PURCHASE_PUA:
        return this.formData.additionsPaymentAmount < this.formData.additionsPaymentMin && this.formData.additionsPaymentAmount !== 0
      case this.CONSTANTS.PAYMENT_TYPES.LOAN:
        return this.formData.loanPaymentAmount < this.formData.loanPaymentMin
      case this.CONSTANTS.PAYMENT_TYPES.INITIAL_PAYMENT:
        return this.formData.initialPaymentAmount < this.formData.initialPaymentMin
      default:
        return 'INVALID TYPE'
    }
  }

  hasPaymentDueOfType(type) {
    switch (type) {
      case this.CONSTANTS.PAYMENT_TYPES.PREMIUM:
        return this.premiumPaymentDue.length > 0
      case this.CONSTANTS.PAYMENT_TYPES.PURCHASE_PUA:
        return this.additionsPaymentDue.length > 0
      case this.CONSTANTS.PAYMENT_TYPES.LOAN:
        return this.loanPaymentDue.length > 0
      case this.CONSTANTS.PAYMENT_TYPES.INITIAL_PAYMENT:
        return this.initialPaymentDue.length > 0
      default:
        return 'INVALID TYPE'
    }
  }

  hasPaymentRange(type) {
    switch (type) {
      case this.CONSTANTS.PAYMENT_TYPES.PREMIUM:
        return this.formData.premiumPaymentMin !== this.formData.premiumPaymentMax
      case this.CONSTANTS.PAYMENT_TYPES.PURCHASE_PUA:
        return this.formData.additionsPaymentMin !== this.formData.additionsPaymentMax
      case this.CONSTANTS.PAYMENT_TYPES.LOAN:
        return this.formData.loanPaymentMin !== this.formData.loanPaymentMax || this.formData.loanPaymentMin === this.formData.loanPaymentMax // We want to show the loan input even if the min/max are equal
      case this.CONSTANTS.PAYMENT_TYPES.INITIAL_PAYMENT:
        return this.formData.initialPaymentMin !== this.formData.initialPaymentMax // default to initial payment for backwards compatibility
      default:
        return 'INVALID TYPE'
    }
  }

  isPennCheckMode() {
    return this.formData.paymentModeCode === this.CONSTANTS.PAYMENT_MODE_TC.monthly && this.formData.paymentMethodCode === this.CONSTANTS.paymentMethods.electronicFundsTransfer.typeCode
  }

  _showHttpError() {
    switch (this.paymentDetailsStatus) {
      case this.CONSTANTS.httpErrors.internalServer.typeCode:
        this.httpErrorMessage = this.CONSTANTS.httpErrors.internalServer.description
        break
      case this.CONSTANTS.httpErrors.notFound.typeCode:
        this.httpErrorMessage = this.CONSTANTS.httpErrors.notFound.description
        break
      default:
        this.httpErrorMessage = this.CONSTANTS.httpErrors.default.description
    }
  }

  scrollToConfirmationOnMobile() {
    if (this.isPhone) {
      this.$anchorScroll.yOffset = 70
      this.$location.hash('top-of-payment-confirmation')
      this.$anchorScroll()
    }
  }

  tryAgain() {
    this.formData.errorCode = null
    this.formData.errorMessage = null
    this.formData.confirmationNumber = null
    this.formData.disclaimer = false
    this.paymentForm?.$setPristine()
  }

  calculateTotalPayment(): number {
    let total: number = Number(this.formData.initialPaymentAmount) + Number(this.formData.premiumPaymentAmount)

    if (this.isTraditional) total += this.formData.additionsPaymentAmount

    if (this.canPay.loan) total += this.formData.loanPaymentAmount

    return total
  }

  getFullAccountType(accountType) {
    if (accountType === 'C') {
      return 'Checking'
    }
    return 'Savings'
  }

  getLatestBill(data) {
    if (data && data.documents.length !== 0) {
      this.dateUtils.sortDocumentsInDescendingOrder(data)
      this.latestBill = data.documents[0].docId
    }
  }

  showCorrespondence() {
    return this.correspondenceService.showCorrespondence(this.latestBill)
  }

  submitPayment() {
    this.submissionInProgress = true

    const gaData = {
      event: 'payment-inforce-submission',
      policyId: this.policyId,
      amount: this.calculateTotalPayment(),
    }

    if (this.selectedPaymentAccountId !== 'CREATE') {
      this.formData.accountId = this.selectedPaymentAccountId
    }

    this.epayService.savePaymentInfo(this.policyId, this.formData)
    if (window.dataLayer) {
      window.dataLayer.push(gaData)
    }

    this.policyService.makePayment(this.policyId, this.formData)
      .then(data => {
        this._processMakePaymentResults(data)
        this.loanOnlyPayment = this._loanOnlyPayment(this.formData)
      })
      .then(() => {
        if ((this.formData.accountNumber && this.formData.accountNickname === undefined) || this.formData.accountNickname === '') {
          this.formData.accountNickname = this.getFullAccountType(this.formData.accountType) + '-XXX' + this.formData.accountNumber.substr(this.formData.accountNumber.length - 4)
        }
        this.submissionInProgress = false
      })
  }

  _loanOnlyPayment(formData) {
    return !formData.premiumPaymentAmount
  }

  emptyInputHandler() {
    this.determineAppliedValues()
    this.$rootScope.$applyAsync(() => {
      let lp = this.formData.loanPaymentAmount
      let pua = this.formData.additionsPaymentAmount

      if (!lp) lp = this.formData.loanPaymentAmount = 0
      if (!pua) pua = this.formData.additionsPaymentAmount = 0
    })

    // if (!value) {
    //   this.formData[propertyName] = 0
    // }
  }

  goToCoverage = () => this.stateUtils.checkUserAndGotoState('coverages')

  getPennCheckDayString(tc) {
    const r = this.CONSTANTS.PENNCHECK_WITHDRAWAL_DATES.filter((item) => {
      return item.dom === tc
    })[0]

    return (r?.date) || ''
  }

  printConfirmation(data) {
    const el = document.getElementById(data)
    const popup = window.open('', 'Payment Confirmation', 'height=400,width=600, top=50, left=200 scrollbars=yes')

    if (!el) throw new Error('[ClientPaymentController::printConfirmation] Could not find element: ' + data)
    if (!popup) throw new Error('[ClientPaymentController::printConfirmation] Failed to open `Payment Confirmation` window')

    const printContents = el.innerHTML
    const styleElements = document.querySelectorAll('link[rel=stylesheet]')
    let i

    popup.document.write('<html><head><title>Payment Confirmation</title>')
    for (i = 0; i < styleElements.length; i++) {
      const styleEl: HTMLLinkElement = styleElements[i] as HTMLLinkElement

      popup.document.write('<link rel="stylesheet" href="' + styleEl.href + '" />')
    }
    popup.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no, viewport-fit=cover"/>')
    popup.document.write('</head><body>')
    popup.document.write('<div class="print-popup">' + printContents + '</div>')
    popup.document.write('</body></html>')
    popup.document.close()
    popup.onload = () => {
      popup.focus()
      if (!this.isIE && !this.isMobileSafari) {
        popup.print()
        if (!this.isPhone) {
          this.$timeout(() => {
            popup.close()
          }, 100)
        }
      }
    }

    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'payment-inforce-print-receipt',
        policyId: this.policyId,
      })
    }
  }

  _afterGetPaymentAccounts(result) {
    let defaultAccounts

    this.selectedPaymentAccountId = 'CREATE'

    if (result.data && !result.error) {
      this.paymentAccounts = result.data
      if (result.data.length !== 0) {
        defaultAccounts = this.paymentAccounts.filter(account => account.defaultAccount === true)
        if (defaultAccounts.length > 0) {
          this.selectedPaymentAccountId = defaultAccounts[0].accountId
        } else {
          this.selectedPaymentAccountId = this.paymentAccounts[0].accountId
        }
      }

      this.$scope.$watch('ctrl.selectedPaymentAccountId', () => this._onSelectedPaymentAccountIdChange())
    }

    if (this.paymentAccounts.length < 5) {
      this.paymentAccounts.push({ accountId: 'CREATE', nickname: 'Create New Payment Account' })
    }
  }

  _resolveShowLoanUI() {
    const features = this.configService.features || {}

    return Boolean(features.showCanPayLoan)
  }

  /**
   * Loan payment min is calculated differently for anniversary bills.
   * This method check to see if the bill is an anniversary bill, and
   * if so uses the loan interest due as the minimum.
   */
  _resolveLoanPaymentMin() {
    if (this.isAnniversaryBill) {
      return 0
    } else {
      return this.$scope.loanPayoffQuote.loanBalance <= this.ePayMetadata.achLimits.min ? this.$scope.loanPayoffQuote.loanBalance : this.ePayMetadata.achLimits.min
    }
  }

  determineAppliedValues() {
    if (this.loanInterest) {
      if (this.formData.loanPaymentAmount <= this.loanInterest.amount) {
        this.appliedInterest = this.$filter('currency')(this.formData.loanPaymentAmount)
        this.appliedPrincipal = this.$filter('currency')(0)
      } else {
        this.appliedInterest = this.$filter('currency')(this.loanInterest.amount)
        this.appliedPrincipal = this.$filter('currency')(this.formData.loanPaymentAmount - this.loanInterest.amount)
      }
    }

    this.anniversaryPaymentIsValid = this.setAnniversaryPaymentValidity(this.formData.loanPaymentAmount, this.formData.loanPaymentMax)

  }

  setAnniversaryPaymentValidity(paymentAmt: number, totalPayoff: number): boolean {
    return paymentAmt <= totalPayoff
  }

  _finalizeInitialization() {
    // creates array of payment type typecodes that are available to pay now
    const canPayTCs = this.$scope.canPayDetails.paymentOptions.map((p) => {
      return p.paymentTypeCode
    })

    // clone passed in scope values so we don't mutate them in the calling page's scope
    this.policySummary = this.$scope.policySummary
    this.policy = {...this.$scope.policySummary}
    this.paymentDetails = {...this.$scope.paymentDetails}
    this.isAMec = this.policySummary.mecInd
    if (!this.isAMec) {
      this.mecAmount = this.paymentDetails?.payments?.[0].mecAmount
    }

    this.paymentDetails.payments = this.paymentDetails.payments || []
    this.policyIsUniversalLife = this.isUniversalLifePolicy()
    this.policyIsUlOrIul = this.isUlOrIul()
    this.policyIsVul = this.isVul()
    this.closeWidget = () => {
      this.formData = this.epayService.getNewPaymentModel()
      this.$scope.closeCallback()
    }

    this.paymentDetailsError = this.paymentDetails.error
    this.paymentDetailsStatus = this.paymentDetails.status
    this.httpErrorMessage = ''

    this.isTraditional = this.policyUtils.isTraditional(this.policy.productType)
    this.insureds = this.policy.insureds.map(i => i.fullName)
    this.owners = this.policy.owners.map(i => i.fullName)

    // changes it from string to actual date object
    this.paymentDetails.paymentDueDate = Date.parse(this.paymentDetails.paymentDueDate)

    // all payments that can be paid
    this.paymentDetails.payments = this.paymentDetails.payments.filter(p => canPayTCs.indexOf(p.tc) !== -1) || []

    // payments broken down by type
    this.initialPaymentDue = this.paymentDetails.payments.filter(p => p.tc === this.CONSTANTS.PAYMENT_TYPES.INITIAL_PAYMENT)
    this.additionsPaymentDue = this.paymentDetails.payments.filter(p => p.tc === this.CONSTANTS.PAYMENT_TYPES.PURCHASE_PUA)
    this.premiumPaymentDue = this.paymentDetails.payments.filter(p => p.tc === this.CONSTANTS.PAYMENT_TYPES.PREMIUM)
    this.loanPaymentDue = this.paymentDetails.payments.filter(p => p.tc === this.CONSTANTS.PAYMENT_TYPES.LOAN)

    // The following can/will eventually replace the use of canPayTCs
    const tmpPaymentOptions = this.utils.getData(this.$scope, 'canPayDetails.paymentOptions') || []

    this.canPay = {
      premium: Boolean(this.utils.getData(tmpPaymentOptions.filter(p => p.paymentTypeCode === this.CONSTANTS.PAYMENT_TYPES.PREMIUM)[0], 'isPayable')),
      initialPayment: Boolean(this.utils.getData(tmpPaymentOptions.filter(p => p.paymentTypeCode === this.CONSTANTS.PAYMENT_TYPES.INITIAL_PAYMENT)[0], 'isPayable')),
      pua: Boolean(this.utils.getData(tmpPaymentOptions.filter(p => p.paymentTypeCode === this.CONSTANTS.PAYMENT_TYPES.PURCHASE_PUA)[0], 'isPayable')),
      loan: Boolean(this.utils.getData(tmpPaymentOptions.filter(p => p.paymentTypeCode === this.CONSTANTS.PAYMENT_TYPES.LOAN)[0], 'isPayable')),
    }

    if (this.paymentDetailsError) {
      this._showHttpError()
    }

    if (this.epayService.hasSavedData(this.policyId)) {
      this.formData = this.epayService.getPaymentInfo(this.policyId)
      this.formData.errorMessage = null
    } else {
      this.formData = this.epayService.getNewPaymentModel()
      // console.log(this.formData)

      this.formData.paymentMode = this.paymentDetails.paymentMode.value
      this.formData.paymentModeCode = this.paymentDetails.paymentMode.tc
      this.formData.paymentMethodCode = this.paymentDetails.paymentMethod.tc

      if (this.paymentDetails.paymentMode.tc === this.CONSTANTS.PAYMENT_MODE_TC.monthly) {
        this.formData.pennCheckDate = this.CONSTANTS.PENNCHECK_WITHDRAWAL_DATES[0].dom
        this.formData.saveAccountInfo = true
      }

      if (this.initialPaymentDue.length > 0) {
        this.formData.initialPaymentAmount = this._getAmount(this.initialPaymentDue)
        this.formData.initialPaymentDefault = this.formData.initialPaymentAmount
        this.formData.initialPaymentMin = this._getMinAmount(this.initialPaymentDue)
        this.formData.initialPaymentMax = this._getMaxAmount(this.initialPaymentDue)
      }

      if (this.premiumPaymentDue.length > 0) {
        this.formData.premiumPaymentAmount = this._getAmount(this.premiumPaymentDue)
        this.formData.premiumPaymentDefault = this.formData.premiumPaymentAmount
        this.formData.premiumPaymentMin = this._getMinAmount(this.premiumPaymentDue)
        this.formData.premiumPaymentMax = this._getMaxAmount(this.premiumPaymentDue)
        this.formData.premiumPaymentBase = this.premiumPaymentDue[0].baseAmount || 0
        this.formData.premiumPaymentDividend = this.premiumPaymentDue[0].divAmount || 0

        this.formData.premiumPaymentDueAllowedValues = {
          numbers: [],
          ranges: [{
            maximum: this.formData.premiumPaymentMax,
            minimum: this.formData.premiumPaymentMin,
          }],
        }

      }

      if (this.canPay.loan) {
        this.loanInterest = this.loanPaymentDue[0]
        this.isAnniversaryBill = !!this.loanInterest

        // NOTE: This may not be needed anymore
        // this.loanPaymentDue = [{
        //   amount: this.$scope.loanPayoffQuote.loanBalance,
        //   interestAmt: this.$scope.loanPayoffQuote.loanIntAmtDue,
        //   maximumAmount: this.$scope.loanPayoffQuote.loanBalance,
        //   minimumAmount: this.$scope.loanPayoffQuote.loanBalance <= 25 ? this.$scope.loanPayoffQuote.loanBalance : 25,
        //   tc: '2',
        //   type: 'Loan Payment',
        // }]

        this.formData.loanPaymentAmount = this.isAnniversaryBill ? this.loanInterest.amount : 0
        this.formData.loanPaymentDefault = 0
        this.formData.loanPaymentMin = this._resolveLoanPaymentMin()
        this.formData.loanPaymentMax = this.$scope.loanPayoffQuote.loanBalance
        this.formData.paymentLoanAllowedValues = {
          numbers: this.canPay.premium ? [0] : [],
          ranges: [{
            maximum: this.formData.loanPaymentMax,
            minimum: this.formData.loanPaymentMin,
          }],
        }

        this.appliedInterest = this.$filter('currency')(this.formData.loanPaymentAmount)
        this.appliedPrincipal = this.$filter('currency')(0)
        this.loanPrincipal = this.$scope.loanPayoffQuote.loanAmt
        this.anniversaryPaymentIsValid = true
      }

      if (this.additionsPaymentDue.length > 0) {
        this.formData.additionsPaymentAmount = this._getAmount(this.additionsPaymentDue)
        this.formData.additionsPaymentDefault = this.formData.additionsPaymentAmount
        this.formData.additionsPaymentMin = this._getMinAmount(this.additionsPaymentDue)
        this.formData.additionsPaymentMax = this._getMaxAmount(this.additionsPaymentDue)
        this.formData.isTraditional = this.isTraditional
        this.formData.paymentAdditionsAllowedValues = {
          numbers: [0],
          ranges: [{
            maximum: this.formData.additionsPaymentMax,
            minimum: this.formData.additionsPaymentMin,
          }],
        }
      }
    }

    if (this.$stateParams.errorCode) {
      this.formData.errorCode = this.$stateParams.errorCode
    }
  }

  _inRange(range, v) {
    return range && range.minimum <= v && range.maximum >= v
  }

  _validatePayments(changes) {
    if (changes && this.paymentForm) {
      const loanAllowedValues = this.formData.paymentLoanAllowedValues
      const puaAllowedValues = this.formData.paymentAdditionsAllowedValues
      const lp = this.formData.loanPaymentAmount
      const pua = this.formData.additionsPaymentAmount
      const totalOverMax = this.calculateTotalPayment() > this.ePayMetadata.achLimits.max

      if (lp && !pua) {
        if (puaAllowedValues) puaAllowedValues.numbers = [0]
      } else if (pua && !lp) {
        if (loanAllowedValues) loanAllowedValues.numbers = [0]
      } else if (!lp && !pua) {
        if (loanAllowedValues) loanAllowedValues.numbers = [0]
        if (puaAllowedValues) puaAllowedValues.numbers = [0]
      }

      if (totalOverMax) {
        this.overPaymentMaxMessage = `The payment amount entered is over the maximum allowable amount of ${this.$filter('currency')(this.ePayMetadata.achLimits.max)}.`
      } else {
        this.overPaymentMaxMessage = ''
      }

      this.$timeout(() => {
        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
        this.paymentForm.puaPaymentAmount && this.paymentForm.puaPaymentAmount.$validate()
        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
        this.paymentForm.anniversaryLoanPaymentAmount && this.paymentForm.anniversaryLoanPaymentAmount.$validate()
      }).then(() => {
        this.hasPayment = this.canPay.premium || this.formData.additionsPaymentAmount + this.formData.loanPaymentAmount > 0
        this.payButtonEnabled = this.paymentForm.$valid && this.formData.disclaimer && this.hasPayment && this._paymentAccountOK() && !totalOverMax
        // console.log('paymentForm.$valid: %s, disclaimer: %s, hasPayment: %s, additionsPaymentAmount: %s, loanPaymentAmount: %s, this._paymentAccountOK(): %s', this.paymentForm.$valid, this.formData.disclaimer, this.hasPayment, this.formData.additionsPaymentAmount, this.formData.loanPaymentAmount, this._paymentAccountOK())
      })
    }
  }

  _getHoUserCanPayResult(id) {
    return this.policyService.getPolicyHoUserCanPay(id).then((result) => {
      return result.data
    })
  }

  _setInputAutocomplete() {
    return this.isARealClient ? 'on' : 'off'
  }

  init() {
    this.ePayMetadata = this.$scope.summaryCtrl.ePayMetadata

    this.$scope.$watchCollection('ctrl.formData', (changes) => this._validatePayments(changes))

    this.showLoanUI = this._resolveShowLoanUI()

    // NOTE: during unit testing $stateParams is not mocked, so `id` winds up being `undefined`
    this.id = this.$stateParams.id

    this.isARealClient = this.authService.isARealClient()

    this.policyId = this.$scope.policySummary.polNumber

    this.marketingName = this.$scope.policySummary.marketingName

    this.autocomplete = this._setInputAutocomplete()

    this.correspondenceService.getFilteredCorrespondences([this.policyId], [this.CONSTANTS.correspondenceDocTypes.bill.typeCode])
      .then(correspondences => this.getLatestBill(correspondences))
      .then(() => this.clientService.getPaymentAccounts(false)) // Todd said we're not supporting credit cards for inforce payments
      .then(result => this._afterGetPaymentAccounts(result))
      .catch(() => {
        // console.error(err)
        this.selectedPaymentAccountId = 'CREATE'
        this.paymentAccounts.push({ accountId: 'CREATE', nickname: 'Create New Payment Account' })
      })
    // .then(() => this._finalizeInitialization())
    this._finalizeInitialization()
    // this.emptyInputHandler();
    // this.payButtonEnabled = this.formData.additionsPaymentAmount + this.formData.loanPaymentAmount > 0
    if (this.configService.serverMode !== 'client') {
      this._getHoUserCanPayResult(this.id).then((result) => {
        this.hoUserCanPay = result.isTransactable
      })
    }
    this.$timeout(() => {
      // console.log('>>>> THERE <<<<')
      const $elForm = this.utils.jqLiteFindElementBySelector('#payment-form')
      const scope = this.utils.getElementScope($elForm)

      if (this.shouldShowForm()) {
        this.paymentForm = scope.paymentForm
        this._validatePayments(this.formData)
      }
    })

    this.mailingAddresses = this.companiesService.resolveDisplayAddresses([{company: this.policy.carrierCode}], [{policyNumber: this.policy.polNumber}])
  }
}
