
import {SearchService} from './search-service'
export class ResultController {
  currentPage: number
  loadingData: boolean
  moreData: boolean
  recentlyViewed: []
  public clients: any[] = []
  messageResult: string
  SCROLL_DISTANCE: number
  showEmptyState: boolean
  showSearchResults: boolean = false
  isPii: boolean = false
  criteria: string

  constructor (private $rootScope, private $location, public searchService: SearchService, private recentlyViewedService, private $stateParams,
     public authService, private pendingPolicyService, private clientAdvisorService, private utils, private dateUtils, private policyUtils,
      private CONSTANTS) {
    this.currentPage = 1
    this.loadingData = false
    this.moreData = true
    this.recentlyViewed = []
    this.messageResult = ''
    this.SCROLL_DISTANCE = 1
    this.showEmptyState = false
  }

  isPastDueDate (dueDate) {
    return this.dateUtils.isPastDueDate(dueDate)
  }

  isClientListEmpty (): boolean {
    return this.clients.length === 0
  }

  showPolicy (resultList) {
    const policy = resultList[0]

    if (policy) {
      this.policyUtils.gotoPolicySummary(policy)
    }
  }

  isSuccess (result): boolean {
    if (result.error && result.status !== 404) {
      this.clients = []
      this.moreData = false
      this.messageResult = result.error
      return false
    }
    return true
  }

  prefetchClientData (clients) {

    if (clients && clients.length) {
      for (let i = 0; i < this.CONSTANTS.prefetchConfig.clientsRecordsCount && i < clients.length; i++) {
        this.clientAdvisorService.getClientInfo(clients[i].clientID, i + 1)
      }
    }
  }

  search () {
    const searchCriteria = this.utils.isSsn(this.criteria) ? this.criteria.replace(/-/g, '') : this.criteria

    // searchCriteria = searchCriteria.replace(/-/g, '')  // replace any '-' characters in an ssn

    if (searchCriteria !== '') {
      if (this.utils.isStringDigitsOnly(searchCriteria) && ![7,9,10].includes(searchCriteria.length)) {
        this.messageResult = 'Policy numbers should be 7, 9 or 10 digits long'
      } else {
        this.loadingData = true
        this.moreData = true
        if (searchCriteria.length === 9 && this.utils.isStringDigitsOnly(searchCriteria)) {
          this.moreData = false // just 1 result is for ssn
          this.isPii = true
          this.searchService.searchByTaxId(searchCriteria.toUpperCase(), this.currentPage)
              .then((result) => this.processResults(result))
        } else {
          this.isPii = false
          this.searchService.search(searchCriteria.toUpperCase(), this.currentPage)
              .then((result) => this.processResults(result))
        }
      }
    } else {
      this.clients = []
    }
  }

  isScrollDisabled () {
    return !this.criteria || this.loadingData || !this.moreData
  }

  isLoadingMore () {
    return this.loadingData && this.clients.length > 0
  }

  nextSearchPage () {
    if ((this.currentPage > 1) && this.moreData && !this.loadingData) {
      this.search()
    }
  }

  public displayAsPending (policy) {
    return Boolean(this.policyUtils.isPendingPolicy(policy) || policy.subStatus)
  }

  isRecentlyViewedVisible () {
    let currentPath = this.$location.path()
    let isResultPath = currentPath.indexOf('/result/') !== -1

    let recentlyViewedVisible = (this.recentlyViewed.length > 0) && this.isClientListEmpty() && !this.loadingData && isResultPath

    this.$rootScope.isRecentLinkActive = recentlyViewedVisible
    return recentlyViewedVisible
  }

  isEmptyState () {
    return this.showEmptyState && !this.loadingData
  }

  updateMessage () {
    if (this.isClientListEmpty()) {
      this.messageResult = `Sorry, no results found for ${this.utils.ssnMask(this.criteria)}`
    } else {
      this.messageResult = `Showing search results for ${this.utils.ssnMask(this.criteria)}`
    }
  }

  $onInit () {
    let hiddenCriteria = this.$stateParams.nonUrlCriteria

    if (hiddenCriteria) {
      this.criteria = hiddenCriteria
    } else {
      this.criteria = this.$stateParams.c
    }

    if (this.criteria) {
      this.search()
    }

    this.pendingPolicyService.keepFirst10()
    this.recentlyViewedService.getListPromise()
        .then((recentlyViewed) => {
          this.recentlyViewed = recentlyViewed ?? []
          this.showEmptyState = this.recentlyViewed.length === 0
        })
  }

  private processResults (result) {
    this.loadingData = false
    if (this.isSuccess(result)) {
      if (result.status === 404 || result.resultList.length === 0) {
        this.updateMessage()
        this.moreData = false
      } else if (result.resultType === this.CONSTANTS.searchTypeResult.client) {
        this.clients = this.clients.concat(result.resultList)
        this.moreData = true

        this.updateMessage()
        this.showSearchResults = true

        if (this.currentPage === 1) {
          this.prefetchClientData(this.clients)
        }
        this.currentPage++
      } else {
        this.showSearchResults = false
        this.showPolicy(result.resultList)
      }
    }
  }
}
