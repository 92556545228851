import * as angular from 'angular'
const apiPrefix = '/credits' //

interface Credit {
  lifePolicyCount: number
}
interface CreditResponse {
  hasError: boolean,
  status: String
  credits: Credit[]  // tell bobs to pluralize these!
}

const BONUS_CREDITS_LABELS = {
  cumulativeFTBonusCredits: 'Cumulative FT Bonus Credits',
  currentBonusEarned: 'Current Bonus Earned ',
  htkContractCredit: 'HTK Contract Credit',
  htkContractStatus: 'HTK Contract Status',
}

export class ClubsService {
  static $inject = ['$http', '$q', 'utils', 'authService', 'partyService', 'dateUtils', 'CONSTANTS']

  constructor(private $http: angular.IHttpService, private $q: angular.IQService, private utils: any, private authService: any, private partyService: any, private dateUtils: any, private CONSTANTS: any) {
    // axios.defaults.headers.common['SSO_TOKEN'] = auth
  }

  getSummaryCredits(filterKey?: string) {
    return this.$q((resolve) => {
      this.$http.get<CreditResponse>(this.utils.appendURLParameter(`${apiPrefix}`, 'filterKey', filterKey))
        .then((response) => {
          response.data.credits.forEach((credit) => {
            if (!credit.lifePolicyCount) {
              credit.lifePolicyCount = 0
            }
          })
          resolve(response.data)
        }).catch(err => {
          resolve({
            credits: [{}, {}, {}],
            hasError: true,
            status: err.status,
          })
        })
    })
  }

  getStarCredits(filterKey?: string) {
    return this.$http.get(this.utils.appendURLParameter(`${apiPrefix}/star`, 'filterKey', filterKey))
  }

  transformBonusData(hasError: boolean, bonusData: any): any {
    if (hasError) return []

    // const canAccess: any = this.authService.getCanAccessResponse()
    // const activeAgent: any = this.partyService.getActiveAgent()
    const dateFormatter = new Intl.DateTimeFormat('en-US', {
      month: 'long',
      day: 'numeric',
    })
    const currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })
    const sDate: Date = this.dateUtils.createDateFromString(bonusData.bonusPeriod.timePeriodStartDate)
    const eDate: Date = this.dateUtils.createDateFromString(bonusData.bonusPeriod.timePeriodEndDate)
    const sDateFormatted: String = dateFormatter.format(sDate)
    const eDateFormatted: String = dateFormatter.format(eDate)
    const htkContractStatus: String[] = [BONUS_CREDITS_LABELS.htkContractStatus, bonusData.htkContractStatus === 'Y' ? 'Yes' : 'No']
    const bonusTable: string[] = Object.entries(bonusData.creditBonus).map((entry: any[]): any => {
      const [key, value] = entry
      const bonusLabel: string = `${BONUS_CREDITS_LABELS[key]} ${sDateFormatted} - ${eDateFormatted} period`

      return [bonusLabel, currencyFormatter.format(value)]
    }).concat([htkContractStatus])

    if (bonusData.htkContractStatus !== 'Y') {
      // Remove the last two  entries when not and HTK user
      bonusTable.pop()
      bonusTable.pop()
    }

    return bonusTable
  }

  getBonusCredits(filterKey?: string): angular.IPromise<any> {
    return this.$http.get(this.utils.appendURLParameter(`${apiPrefix}/bonus`, 'filterKey', filterKey))
      .then((bonusResult) => {
        const bonusData: any = bonusResult?.data || { error: 'Unexpected data response from Club Credits Bonus endpoint.' }
        const error: any = bonusData.error
        const bonusTransformedData = {
          creditsTable: this.transformBonusData(error !== null, bonusData.data),
          error,
        }

        return bonusTransformedData
      })
      .catch(() => {
        console.warn(`No club credits for current filterKey: ${filterKey}.`)

        return { error: null, creditsTable: [] }
      })
  }

  lifeSalesRanking(filterKey?: string): angular.IPromise<any> {
    return this.$http.get(this.utils.appendURLParameter(`${apiPrefix}/lifeSalesRanking`, 'filterKey', filterKey))
  }

  getCredit(creditType, filterKey?: string) {
    return this.$http.get(this.utils.appendURLParameter(`${apiPrefix}/${creditType}`, 'filterKey', filterKey))
  }

  getLeadersSummary(filterKey?: string) {
    return this.$http.get(this.utils.appendURLParameter(`${apiPrefix}/leaders`, 'filterKey', filterKey))
  }

  getChairmansSummary(filterKey?: string) {
    return this.$http.get(this.utils.appendURLParameter(`${apiPrefix}/chairmans`, 'filterKey', filterKey))
  }

  getDetailCredits(clubMethodType, creditType, filterKey?: string) {
    return this.$q((resolve) => {
      this.$http.get(this.utils.appendURLParameter(`${apiPrefix}/${clubMethodType}/${creditType}`, 'filterKey', filterKey))
        .then((response) => {
          resolve({ credits: response.data, hasError: false })
        }).catch(err => {
          resolve(
            {
              credits: {},
              hasError: true,
              status: err.status,
            })
        })
    })
  }

  getAgentWhoAmI(filterKey: string): any {
    let url = `${this.CONSTANTS.apiRoot}/agent/whoami`

    url = this.utils.appendURLParameter(url, 'filterKey', filterKey)

    return this.$http.get(url)
      .then((result: any) => {

      return result.data
    })
    .catch((err) => {
      console.log(err)
    })
  }

}

