import * as angular from 'angular'

import { clientUtils } from './client-utils'
import { dateUtils } from './date-utils'
import { deviceUtils } from './device-utils'
import { capitalize, capitalizeWithOverrides, changeDecimalToHalf, displayMaskedPhoneNumber, displayPhoneNumber, dynamicFilter, emDash, formatPolicyNumber, humanizeBytes, inForceOverPending, isEmpty, maskedPhoneNumber, maskedSSN, nameCase, notAvailable, notAvailableTable, numberToLiteralString, percent, percentOrDough, percentOrValue, phoneFormat, slice, yesNo, zeroDefault, none, negativeParenthesis} from './filters'
import { LoggingService } from './logging'
import { OverlayUtils } from './overlay-utils'
import { policyUtils } from './policy-utils'
import { popupManager } from './popup-manager.service'
import { stringUtils } from './string-utils'
import { utils } from './utils'
import { valueCheckers } from './value-checkers'
import { CrafterService } from './crafter-service'

angular
    .module('app.logging', [])
    .service('loggingService', LoggingService)

angular.module('app.utils', ['angular-md5', 'app.core'])
    .factory('clientUtils', clientUtils)
    .factory('dateUtils', dateUtils)
    .factory('deviceUtils', deviceUtils)
    .filter('notAvailable', notAvailable)
    .filter('notAvailableTable', notAvailableTable)
    .filter('notApply', notAvailable)
    .filter('emDash', emDash)
    .filter('phoneFormat', phoneFormat)
    .filter('yesNo', yesNo)
    .filter('zeroDefault', zeroDefault)
    .filter('percentOrValue', percentOrValue)
    .filter('percentOrDough', percentOrDough)
    .filter('percent', percent)
    .filter('nameCase', nameCase)
    .filter('inForceOverPending', inForceOverPending)
    .filter('changeDecimalToHalf', changeDecimalToHalf)
    .filter('dynamicFilter', dynamicFilter)
    .filter('slice', slice)
    .filter('isEmpty', isEmpty)
    .filter('capitalize', capitalize)
    .filter('capitalizeWithOverrides', capitalizeWithOverrides)
    .filter('numberToLiteralString', numberToLiteralString)
    .filter('formatPolicyNumber', formatPolicyNumber)
    .filter('maskedPhoneNumber', maskedPhoneNumber)
    .filter('displayPhoneNumber', displayPhoneNumber)
    .filter('displayMaskedPhoneNumber', displayMaskedPhoneNumber)
    .filter('negativeParenthesis', negativeParenthesis)
    .filter('humanizeBytes', humanizeBytes)
    // .service('loggingService', LoggingService)
    .factory('overlayUtils', OverlayUtils)
    .factory('policyUtils', policyUtils)
    .factory('popupManager', popupManager)
    .service('stringUtils', stringUtils)
    .service('crafterService', CrafterService)
    .factory('utils', utils)
    .factory('valueCheckers', valueCheckers)

angular.module('app.utils')
    .filter('notAvailable', notAvailable)
    .filter('notAvailableTable', notAvailableTable)
    .filter('notApply', notAvailable)
    .filter('emDash', emDash)
    .filter('phoneFormat', phoneFormat)
    .filter('yesNo', yesNo)
    .filter('zeroDefault', zeroDefault)
    .filter('percentOrValue', percentOrValue)
    .filter('percentOrDough', percentOrDough)
    .filter('percent', percent)
    .filter('nameCase', nameCase)
    .filter('inForceOverPending', inForceOverPending)
    .filter('changeDecimalToHalf', changeDecimalToHalf)
    .filter('dynamicFilter', dynamicFilter)
    .filter('slice', slice)
    .filter('isEmpty', isEmpty)
    .filter('capitalize', capitalize)
    .filter('capitalizeWithOverrides', capitalizeWithOverrides)
    .filter('numberToLiteralString', numberToLiteralString)
    .filter('formatPolicyNumber', formatPolicyNumber)
    .filter('maskedSSN', maskedSSN)
    .filter('maskedPhoneNumber', maskedPhoneNumber)
    .filter('displayPhoneNumber', displayPhoneNumber)
    .filter('displayMaskedPhoneNumber', displayMaskedPhoneNumber)
    .filter('humanizeBytes', humanizeBytes)
    .filter('none', none)
