import { ClubsService } from "./club-credit-service"

export class ClubCreditsRouter {

  static $inject = ['routerHelper']

  constructor (routerHelper) {
    const myStates = this.getStates()

    routerHelper.configureStates(myStates)
  }

  getStates () {
    return [{
      state: 'credits',
      config: {
        url: '/credits',
        templateUrl: 'app/credits/credits-view.html',
        controller: 'ClubCreditsController',
        controllerAs: 'clubsCtrl',
        data: {
          secure: true,
          module: 'credits',
        },
        resolve: {
          clubCreditsMessage: ['$templateRequest', '$rootScope', '$sce', ($templateRequest, $rootScope, $sce) => {
            return $templateRequest('/insight-content/club-credits-message.html').
              then((message: string) => {
                return { trustedHtml: $sce.trustAsHtml(message), ccEnabled: $rootScope.featureFlags.inlineClubs }
              })
          }],
          activeAgent: ['partyService', 'authService', (partyService, authService) =>
              !partyService.getActiveAgent() || partyService.getActiveAgent().name === 'All' ? { name: authService.getFullName()} : partyService.getActiveAgent()],
          activeAgentKey: ['partyService', (partyService) => partyService.getAgentKey()],
          summary: ['clubsService', 'activeAgentKey', (clubsService, activeAgentKey) =>
              clubsService.getSummaryCredits(activeAgentKey)],
          starCredits: ['clubsService', 'activeAgentKey', (clubsService, activeAgentKey) =>
              clubsService.getStarCredits(activeAgentKey)],
          leaders: ['summary', (summary) => summary.credits[0]],
          chairmans: ['summary', (summary) => summary.credits[1]],
          stacked: ['summary', (summary) => summary.credits[2]],
          investmentResult: ['clubsService', 'activeAgentKey', (clubsService, activeAgentKey) =>
            clubsService.getDetailCredits('leaders', 'investment', activeAgentKey)],
          bonus: ['clubsService', 'activeAgentKey', (clubsService, activeAgentKey) =>
            clubsService.getBonusCredits(activeAgentKey)],
          lifeSalesRanking: ['clubsService', 'activeAgentKey', (clubsService, activeAgentKey) => clubsService.lifeSalesRanking(activeAgentKey)],
          hasError: ['summary', 'investmentResult', 'bonus', (summary, investmentResult, bonus) => summary.hasError || investmentResult.hasError || bonus.error !== null],
          whoAmI: ['clubsService', 'activeAgentKey', (clubsService, activeAgentKey) => clubsService.getAgentWhoAmI(activeAgentKey)],
        },
        onEnter: ['$rootScope','investmentResult', function ($rootScope, investmentResult) {
          let pageTitle = 'Club Credits'

          if (!investmentResult) {
            $rootScope.errorMessage = 'No club credits for you'
          }

          $rootScope.pageTitle = pageTitle
          $rootScope.mobileHeaderTitle = pageTitle
          $rootScope.state = 'credits'
        }],
      },
    },
    ]
  }
}
