import { IPromise } from "angular"
import { CrafterService } from "../../utils/crafter-service"

export class AdBlockDetectorService {
  static $inject: string[] = ['crafterService']

  bannerIds: string[]
  classes: string[]
  selectors: string[][]
  selectorTypes: string[] = ['#', '.']

  constructor(private crafterService: CrafterService) {}

  isBrowser(): boolean {
    return typeof window !== 'undefined'
  }

  /**
   * Check enabling adblock
   *
   * @returns {Boolean} Status adblock enabling
   * @memberof AdblockDetector
   */
  detect(document: Document): boolean {
    const r: boolean[] = this.selectorTypes.map((selectorType: string, index: number) => this.selectors[index].every((bannerId) => this.checkVisibility(document, bannerId, selectorType)))
    const t: boolean = r.some((i: boolean) => !i)

    // console.log('r >>>>', r)
    // console.log('t >>>>', t)

    return t
  }

  /**
   * Generate all ads blocks from ids dictionary
   *
   * @returns {String} Ads blocks
   * @private
   * @memberof AdblockDetector
   */
  public generatesBannersString(): string {
    const divWithIds: string[] = this.bannerIds.map((bannerId) => `<div id="${bannerId}"></div>`)
    const divWithClass: string[] = this.classes.map((classId) => `<div class="${classId}"></div>`)

    return [].concat(divWithIds, divWithClass).join('')
  }

  /**
   * Check visibility by banner id
   *
   * @param {Number} bannerId
   * @returns {Boolean} Return false if bait is not visible
   * @private
   * @memberof AdblockDetector
   */
  checkVisibility(document: Document, bannerId: string, selectorType: string = '#'): Boolean {
    const element: HTMLDivElement = document.querySelector<HTMLDivElement>(`${selectorType}${bannerId}`)
    const visibile: boolean = element ? element.offsetParent !== null : false

    return visibile
  }

  init(): IPromise<void> {
    return this.crafterService.getAdblockerBait()
      .then((bait: any) => {
        this.bannerIds = bait.bannerIds // ['AdHeader', 'AdContainer', 'AD_Top', 'homead', 'ad-lead']
        this.classes = bait.classes // ['policy-box']
        this.selectors = [this.bannerIds, this.classes]
      })
  }

}
